import React, { useEffect } from 'react'
import  AppSidebar  from '../components/AppSideBar'
import AppHeader from '../components/AppHeader';
import AppContent from '../components/AppContent';
import { useSelector } from 'react-redux';

function Dashboard() {
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  useEffect(() => {
    const browserLocation = {}
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        let lat = location.coords.latitude;
        let long = location.coords.longitude;
        browserLocation.latitude = lat;
        browserLocation.longitude = long;
        console.log(lat, long, browserLocation);
        localStorage.setItem('browserLocation', JSON.stringify(browserLocation));
      })
    }
  }, [])
  return (
    <div className={unfoldable ? sidebarShow ? 'm-l-64px': '' : sidebarShow ? 'm-l-256px' : ''}>
        <AppSidebar/>
          <div >
            <AppHeader/>
            <AppContent/>
          </div>
    </div>
  )
}

export default Dashboard