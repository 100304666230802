import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CCardImage, CSidebarBrand, CSidebarNav, CSidebarToggler, CContainer } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { APMC_sidebar, PACS_sidebar, Trader_sidebar } from './navBar'
import { useNavigate } from 'react-router-dom'
import MessageModal from '../widgets/MessageModel'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import upjao_logo from '../assets/upjao.png'

function AppSideBar() {
  const { t } = useTranslation()
  const user_role = localStorage.getItem('user_role')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [logoutModel, setLogoutModel] = useState(false);

  const onGetAccessSideBarContent = () => {
    let sidebar_contents
    switch (user_role) {
      case 'PACS-Admin':
        sidebar_contents = PACS_sidebar()
        break;
      case 'APMC-Admin':
        sidebar_contents = APMC_sidebar()
        break;
      case 'Trader':
        sidebar_contents = Trader_sidebar()
        break
      default:
        sidebar_contents = []
        break;
    }
    return sidebar_contents
  }

  const logout = async () => {
    await signOut(auth).then(() => {
      localStorage.clear();
      i18n.changeLanguage('en');
      navigate("/");
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <CContainer>
      <MessageModal
        isVisible={logoutModel}
        title={t('Logout')}
        message={t('Do you want logout ?')}
        handleClose={() => setLogoutModel(!logoutModel)}
        handleTrigger={logout}
      />

      <CSidebar
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: 'set', sidebarShow: visible })
        }}
      >
        <CSidebarBrand className="d-none d-md-flex cursor bg-white b-r-1px" to="/">
          <CCardImage className={!unfoldable ? 'logo-size' : 'logo-size-contract'} onClick={() => navigate('/')} src={upjao_logo} />
        </CSidebarBrand>

        <CSidebarNav className='display-flex justify-content-between bg-white b-r-1px'>
          <SimpleBar>
            <AppSidebarNav items={onGetAccessSideBarContent()} />
          </SimpleBar>

          {/* <SimpleBar>
            <div className='cursor m-l-25px text-align-left p-a-10 h-50' onClick={() => setLogoutModel(!logoutModel)}>
              <span><CIcon icon={cilAccountLogout} customClassName="nav-icon" /></span>
              {!unfoldable && <span style={{ color: '#b5adad' }}> &emsp; {t('Logout')}</span>}
            </div >
          </SimpleBar> */}
        </CSidebarNav>

        <CSidebarToggler className='b-r-1px APP-BACKGROUND' unfoldable="true"
          onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
        ></CSidebarToggler>

      </CSidebar>
    </CContainer>
  )
}

export default AppSideBar