import React from 'react'
import { Page, Document, StyleSheet, Text, View, Image, Font } from "@react-pdf/renderer";
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import PageFooter from './PageFooter';
import upjaoLogo from '../../assets/upjao.png'
import hindi_path from '../../assets/NotoSerifDevanagari_SemiCondensed-Medium.ttf'
import gujarati_path from '../../assets/NotoSerifGujarati-Medium.ttf'

Font.register({
    family: 'my_font_family',
    fontWeight: 'normal',
    src: localStorage.getItem('language') === 'gu' ? gujarati_path : hindi_path
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    heading: {
        color: 'black',
        textAlign: 'center',
        fontSize: 12,
        marginTop: 10,
        marginBottom: 10,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    displayLeft: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 1,
        fontSize: 12,
    },
    challanSubject: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    key: {
        fontSize: 13,
    },
    value: {
        color: 'rgb(49, 48, 48)'
    },
    signature: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 80,
        justifyContent: 'space-between',
    },
    headerRow: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    headerHeading: {
        flex: 1,
        textAlign: 'right',
    },
    line: {
        borderBottom: '1px solid #ca3f03',
        marginBottom: 10
    },
    logo: {
        width: 100,
        height: 60,
    },
    textAlign: {
        textAlign: 'start',
        color: '#ca3f03',
        fontSize: 20,
        fontFamily: 'Helvetica-Bold'
    },
    mt20: {
        marginTop: 20
    },
    fontFamily: {
        fontFamily: 'my_font_family'
    }
});

function ChallanDownload({ crop, company_name, receiver_company_name, lot_id, date_of_billing, trader_address, total_quanity,
    number_of_bags, per_bag_quantity, vehicle_number
}) {
    const { t } = useTranslation();

    const onTranslateContent = () => {
        switch (localStorage.getItem('language')) {
            case 'en':
                return (
                    <Text>
                        This is an informed <Text style={{ fontWeight: 'bold' }}>{company_name}</Text> dispatch {crop} stock to {receiver_company_name} as per the following stock.
                    </Text>
                );
            case 'hi':
                return (
                    <Text>
                        आपको बताया जाता है की <Text style={{ fontWeight: 'bold' }}>{company_name}</Text> ने निचे दिए {crop} के स्टॉक को {receiver_company_name} को भेजा है|
                    </Text>
                );
            case 'gu':
                return (
                    <Text>
                        તમને જાણવામાં આવે છે કે <Text style={{ fontWeight: 'bold' }}>{company_name}</Text> નીચે દર્શાવેલ {crop} નો જથ્થો {receiver_company_name} ને મોકલાવેલ છે.
                    </Text>
                );
            default:
                return '';
        }
    };    

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Page Header */}
                <View style={styles.headerRow}>
                    <View style={styles.headerHeading}>
                        <Text style={[styles.textAlign, styles.fontFamily]}>{t('Challan')}</Text>
                    </View>
                    <Image style={styles.logo} src={upjaoLogo} />
                </View>
                <hr style={styles.line} />

                {/* Page Content */}
                <View style={styles.heading}>
                    <Text>{company_name}</Text>
                </View>

                <View style={styles.row}>
                    <View style={styles.heading}>
                        <View style={styles.displayLeft}>
                            <Text style={styles.fontFamily}>{t('Delivery Challan Number')} - </Text>
                            <Text>{lot_id}</Text>
                        </View>
                    </View>
                    <View style={styles.heading}>
                        <View style={styles.displayLeft}>
                            <Text style={styles.fontFamily}>{t('Date')} - </Text>
                            <Text>{date_of_billing && moment(date_of_billing).format('DD/MM/YYYY')}</Text>
                        </View>
                    </View>
                </View>

                <View>
                    <Text style={styles.fontFamily}>{t('Dear Sir')},</Text>
                    <Text>{receiver_company_name}</Text>
                </View>


                <View style={styles.mt20}>
                    <View style={styles.challanSubject}>
                        <Text style={[styles.key, styles.fontFamily]}>{t('Subject')} - </Text>
                        <Text style={[styles.value, styles.fontFamily]}>{crop} {t('Stock Dispatch')}</Text>
                    </View>
                </View>

                <View style={styles.mt20}>
                    <View style={styles.challanSubject}>
                        <Text style={styles.fontFamily}>{onTranslateContent()}</Text>
                    </View>
                </View>

                <View style={styles.mt20}>
                    <View style={styles.displayLeft}>
                        <Text style={[styles.key, styles.fontFamily]}>{t('Crop')} - </Text>
                        <Text style={styles.value}>{crop}</Text>
                    </View>
                    <View style={styles.displayLeft}>
                        <Text style={[styles.key, styles.fontFamily]}>{t('Bags')} - </Text>
                        <Text style={styles.value}>{number_of_bags}</Text>
                    </View>
                    <View style={styles.displayLeft}>
                        <Text style={[styles.key, styles.fontFamily]}>{t('Per bag Qty')} - </Text>
                        <Text style={styles.value}>{per_bag_quantity}</Text>
                    </View>
                    <View style={styles.displayLeft}>
                        <Text style={[styles.key, styles.fontFamily]}>{t('Total Qty with Bag')} - </Text>
                        <Text style={styles.value}>{total_quanity}</Text>
                    </View>
                    <View style={styles.displayLeft}>
                        <Text style={[styles.key, styles.fontFamily]}>{t('Vehicle Number')} - </Text>
                        <Text style={styles.value}>{vehicle_number}</Text>
                    </View>
                </View>

                <View style={styles.signature}>
                    <View style={styles.heading}>
                        <Text style={styles.fontFamily}>{t('Seller Signature and Stamp')}</Text>
                    </View>
                    <View style={styles.heading}>
                        <Text style={styles.fontFamily}>{t('Purchaser Signature')}</Text>
                    </View>
                </View>
                {/* Footer */}
                <PageFooter />
            </Page>
        </Document >
    )
}

export default ChallanDownload