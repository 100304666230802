import { cilArrowLeft, cilExternalLink, cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React, { useEffect, useState } from 'react'
import { getAllParentCompanyUsers } from '../utils/api'
import { useTranslation } from 'react-i18next'
import TableView from './TableView'

function InvoiceHistroyDetails(props) {
    const { backToInvoiceOrderDetail } = props
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);
    const [allInvoiceDetail, setAllInvoiceDetail] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const response = await getAllParentCompanyUsers()
                const traderList = response?.data?.data && response?.data?.data.filter((element) => (
                    element.user_role === 'Farmer'
                ))
                setAllInvoiceDetail(traderList)
            } catch (error) {
                console.error(error);
            }
            setLoading(!loading)
        })()
    }, [])

    const columns = [
        {
            name: t('Lot ID'),
            selector: row => '234567',
            sortable: true,
        },
        {
            name: `${t('Time and Date')}`,
            selector: row => '10:23 AM, 05-06-2023',
            sortable: true,
        },
        {
            name: t('Generated By'),
            selector: row => 'Name',
            sortable: true,
        },
        {
            name: t('Edit'),
            width: '4rem',
            selector: row => <CIcon className="cursor" icon={cilPencil} />,
            sortable: true,
        },
        {
            name: t('Link'),
            width: '4rem',
            selector: row => <CIcon className="cursor" icon={cilExternalLink} />,
            sortable: true,
        },
    ];

    return (
        <div>
            <TableView
                tableHeading={t('Invoice History List')}
                columns={columns}
                tableContent={allInvoiceDetail}
                dataLoading={loading}
                isBackArrow={true}
                onArrowAction={() => backToInvoiceOrderDetail(true)}
                filterKeys={['first_name', 'last_name', 'phone_number', 'email', 'shortUserId']}
            />
        </div>
    )
}

export default InvoiceHistroyDetails