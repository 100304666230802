import { CCard, CSpinner } from '@coreui/react'
import React, { useEffect, useMemo, useState } from 'react'
import CustomTableStyles from '../CustomTableStyle';
import DataTable from 'react-data-table-component';
import Fuse from 'fuse.js'
import TableHeadingWidget from '../widgets/TableHeadingWidget';
import { withTranslation, useTranslation } from 'react-i18next'

function TableView({ tableContent, columns, tableHeading, filterKeys, isBackArrow, dateRange,
  onArrowAction, dataLoading, isDateFilter, setDateRange, cropList, tradersList, farmersList, no_data_found = null }) {
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const { t } = useTranslation()

  useEffect(() => {
    const fuse = tableContent && new Fuse(tableContent, {
      keys: filterKeys,
      includeScore: true
    })
    const filterFuse = fuse && fuse?.search(filterText)
    const updatedData = filterText ? filterFuse.map(res => res.item) : tableContent;
    setfilteredData(updatedData);
  }, [filterText, tableContent])

  useEffect(() => {
    // setLoading(true)
    setLoading(dataLoading);
  }, [dataLoading])

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <TableHeadingWidget
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        tableHeading={tableHeading}
        onArrowAction={onArrowAction}
        isBackArrow={isBackArrow && true}
        placeholder={t('Search')}
        isDateFilter={isDateFilter}
        setDateRange={setDateRange}
        dateRange={dateRange}
        cropList={cropList}
        tradersList={tradersList}
        farmersList={farmersList}
      />
    );
  }, [filterText, resetPaginationToggle, dateRange]);

  return (
    <div key={tableContent}>
      <div className='m-a-10'>
        <CCard>
          <DataTable
            noDataComponent={<p className='pt-3'>{no_data_found ? no_data_found : t('There are no records to display')}</p>}
            pagination
            paginationRowsPerPageOptions={[25, 50, 75, 100]}
            paginationPerPage={25}
            paginationResetDefaultPage={resetPaginationToggle}
            highlightOnHover
            dense
            theme="solarized"
            subHeader
            subHeaderComponent={subHeaderComponent}
            style={{ margin: '50px !important' }}
            columns={columns}
            data={filteredData}
            customStyles={CustomTableStyles}
            progressPending={loading}
            progressComponent={<CSpinner style={{ margin: '10px', color: 'lightgrey' }} />}
          />
        </CCard>
      </div>

    </div>
  )
}

export default withTranslation()(TableView)