import React, { useEffect } from 'react'
import TableView from './TableView'
import { CButton } from '@coreui/react';
import { getQrCodeHistory } from '../utils/api';

function QrCodeHistory(props) {
    const { viewQrCodeHistory } = props

    useEffect(() => {
        (async () => {
            try {
                const response = await getQrCodeHistory()
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    const data = [
        { date: '09-06-2023', qr_range: '0000-0990', noOfQR: 990, },
        { date: '08-06-2023', qr_range: '0100-0800', noOfQR: 800, },
        { date: '07-06-2023', qr_range: '0250-1000', noOfQR: 750, },
        { date: '06-06-2023', qr_range: '0000-0200', noOfQR: 200, }
    ]

    const columns = [
        {
            name: 'S No',
            selector: (row, index) => index,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date ? row.date : '-',
            sortable: true,
        },
        {
            name: 'QR code Range',
            selector: row => row.qr_range ? row.qr_range : '-',
            sortable: true,
        },
        {
            name: 'No Of QrCode',
            selector: row => row.noOfQR ? row.noOfQR : '-',
            sortable: true,
        },
        {
            name: 'Download',
            selector: row => <CButton color='primary' shape="rounded-pill" variant='outline' size='sm' className='m-tb-2' style={{ padding: '0px 5px' }}>Download</CButton>,
            // sortable: true,
        },
    ];

    return (
        <div>
            <TableView
                tableHeading={'QR History'}
                columns={columns}
                tableContent={data}
                // dataLoading={loading}
                isBackArrow={true}
                onArrowAction={() => viewQrCodeHistory(true)}
                filterKeys={['date', 'qr_range', 'noOfQR']}
            />
        </div>
    )
}

export default QrCodeHistory