import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import upjaoLogo from '../../assets/upjao.png'
import ContentView from './ContentView'
import hindi_path from '../../assets/NotoSerifDevanagari_SemiCondensed-Medium.ttf'
import gujarati_path from '../../assets/NotoSerifGujarati-Medium.ttf'
import { useTranslation } from 'react-i18next';

Font.register({
    family: 'my_font_family',
    fontWeight: 'normal',
    src: localStorage.getItem('language') === 'gu' ? gujarati_path : hindi_path
});
const styles = StyleSheet.create({
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    logo: {
        width: 100,
        height: 60,
    },
    heading: {
        flex: 1,
        textAlign: 'right',
    },
    textContainer: {
        flex: 1,
        textAlign: 'right',
        // backgroundColor: 'aliceblue',
        marginLeft: 20
    },
    textContainer1: {
        flex: 1,
        textAlign: 'left',
        // backgroundColor: 'gray'
    },
    textAlign: {
        textAlign: 'start',
        color: '#ca3f03',
        fontSize: 20,
        fontFamily: 'Helvetica-Bold'
    },
    line: {
        borderBottom: '1px solid #ca3f03',
        marginBottom: 10
    },
    fontFamily: {
        fontFamily: 'my_font_family'
    },
    mb10: { marginBottom: 10 },
    w20: { width: 100 }

});


const PageHeader = ({ company_information, type }) => {
    const { t } = useTranslation()
    return (
        <Fragment>
            <View style={styles.row}>
                <View style={styles.heading}>
                    <Text style={[styles.textAlign, styles.fontFamily]}>{type === 'invoice' ? t('Invoice') : t('Purchase Order')}</Text>
                </View>
                <Image style={styles.logo} src={upjaoLogo} />
            </View>
            <hr style={styles.line} />

            <View style={styles.mb10}>
                <View style={styles.row}>
                    <View style={styles.textContainer1}>
                        <ContentView company_information={company_information[1]} styleText='left' />
                    </View>
                    <View style={styles.w20}></View>
                    <View style={styles.textContainer}>
                        <ContentView company_information={company_information[0]} styleText='right' />
                        {/* <ContentView company_information={company_information[2]} textColor={'darkblue'} /> */}
                    </View>
                </View>
            </View>

            <View>
                <View style={styles.row}>
                    <View style={styles.textContainer1}>
                        <ContentView company_information={company_information[2]} styleText='left' />
                    </View>
                    <View style={styles.w20}></View>
                    <View style={styles.textContainer}>
                        <ContentView company_information={company_information[3]} styleText='right' />
                        {/* <ContentView company_information={company_information[2]} textColor={'darkblue'} /> */}
                    </View>
                </View>
            </View>
        </Fragment>
    )
};

export default PageHeader