import React from 'react';
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import hindi_path from '../../assets/NotoSerifDevanagari_SemiCondensed-Medium.ttf'
import gujarati_path from '../../assets/NotoSerifGujarati-Medium.ttf'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

Font.register({
    family: 'my_font_family',
    fontWeight: 'normal',
    src: localStorage.getItem('language') === 'gu' ? gujarati_path : hindi_path
});

const styles = StyleSheet.create({
    marginTop: { marginTop: 24 },
    heading: {
        padding: '5px',
        color: 'black',
        textAlign: 'center',
        fontSize: 12,
        marginTop: 10,
        marginBottom: 10,
        borderTop: '1px solid black',
        borderBottom: '1px solid black'
    },

    container: {
        width: '100%',
        // paddingHorizontal: 10,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 1,
    },
    tableHeading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'left',
    },
    display: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 1,
    },
    key: {
        fontSize: 10,
        fontFamily: 'Helvetica-Bold',
    },
    value: {
        fontSize: 10,
        textAlign: 'right',
        color: 'rgb(49, 48, 48)'
    },
    priceValue: {
        fontSize: 10,
        textAlign: 'right',
    },
    pricedisplay: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 1,
    },
    pricedisplay1: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 1,
    },
    m10: { marginTop: '10px' },
    m15: { marginTop: '15px' },
    totalSum: {
        // marginTop: '18px',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    sum: {
        color: '#ca3f03',
        fontSize: 18
    },
    generatedBy: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        // textAlign:'right'
    },
    line: {
        borderBottom: '1px solid lightgray',
        marginTop: 2,
        marginBottom: 8
    },
    fontFamily: {
        fontFamily: 'my_font_family'
    },

});

const PageContent = ({ order_item_price,order_crop_detail,totalAmount, generatedBy,serialNumber, type }) => {
    // order info
    // const columnNo = 3
    // const numberOfRows = Math.ceil(order_information.length / columnNo);
    // const rows = [];
    // for (let i = 0; i < numberOfRows; i++) {
    //     const startIndex = i * columnNo;
    //     const endIndex = Math.min(startIndex + columnNo, order_information.length);
    //     const rowItems = order_information.slice(startIndex, endIndex);
    //     rows.push(rowItems);
    // }
    const { t } = useTranslation()

    return (
        <View style={styles.container}>
            <View style={styles.heading}>
                <Text style={styles.fontFamily}>{type === 'invoice' ? `${t('Invoice Number')} - ${serialNumber}` : `${t('Purchase Order Number')} - ${serialNumber}`}</Text>
            </View>
            {/* {rows.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.row}>
                    {row.map((item, itemIndex) => (
                        <View key={itemIndex} style={styles.tableHeading}>
                            <View style={styles.display}>
                                <Text style={styles.key}>{item.name ? item.name+ ':' : ' '}</Text>
                                <Text style={styles.value}>{item.value ? ` ${item.value}` : ' NA'}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            ))} */}

            <View style={styles.m10}>
                {order_crop_detail.map((item, index) => (
                    <View key={index} style={styles.pricedisplay}>
                        <Text style={[styles.key, styles.fontFamily]}>{item.name ? `${t(item.name)}` : ' '}</Text>
                        <Text style={[styles.value, styles.fontFamily]}>{item.value ? ` ${item.value}` : ' NA'}</Text>
                    </View>
                ))}
            </View>

            <hr style={styles.line}/>

            <View>
                {order_item_price.map((item, index) => (
                    <View key={index} style={styles.pricedisplay}>
                        <Text style={[styles.key, styles.fontFamily]}>{item.name ? `${t(item.name)}` : ' '}</Text>
                        <Text style={[styles.priceValue, styles.fontFamily]}>{item.value ? `₹ ${item.value}` : '₹ 0.00'}</Text>
                    </View>
                ))}
            </View>

            <View style={styles.m15}>
                <View style={styles.pricedisplay1}>
                <Text> </Text>
                    {/* <Text>All items are inclusive of GST @ 18%</Text> */}
                    <View>
                        {/* <Text>Total without tax: INR {totalAmount}</Text> */}
                        <Text style={[styles.sum, styles.fontFamily]}>{t('Total')}: ₹ {totalAmount}</Text>
                        {/* <Text>GST (18%): INR 10800.00</Text> */}
                    </View>
                </View>
            </View>

            <View style={styles.totalSum}>
                <View></View>
                <View>
                    <View style={styles.generatedBy}>
                        <Text style={styles.fontFamily}>{(generatedBy.generatedByName || generatedBy.billing_date) ? `${t('Generated By')}: ${generatedBy.generatedByName ? generatedBy.generatedByName : 'NA'} & ${generatedBy.billing_date && moment(generatedBy.billing_date).format('DD-MM-YYYY')}`: ''}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};
export default PageContent