import React, { useEffect, useState } from 'react'
import TableView from './TableView';
import { getDemandList } from '../utils/api';

function Demands() {
  const [loading, setLoading] = useState(false);
  const [demands, setDemands] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(!loading)
      const response = await getDemandList()
      response && setDemands(response.data.data)
    })()
  }, [])

  const columns = [
    {
      name: 'Crop Name',
      selector: row => row.demand_crop_name ? row.demand_crop_name : '-',
      sortable: true,
    },
    {
      name: 'Crop Varirty',
      selector: row => row.demand_crop_variety ? row.demand_crop_variety : '-',
      sortable: true,
    },
    {
      name: 'Price Per KG',
      selector: row => row.demand_max_price ? <p className='display-inline'>&#8377; {row.demand_max_price} / {row.demand_unit} </p> : '-',
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: row => row.demand_quantity ? `${row.demand_quantity} ${row.demand_unit}` : '-',
      sortable: true,
    },
    {
      name: 'Origin Place',
      selector: row => row.demand_origin_place ? row.demand_origin_place : '-',
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: row => row.created_at ? row.created_at : '-',
      sortable: true,
    },
  ];

  return (
    <div>
      <TableView
        tableHeading={'List Of Demands'}
        columns={columns}
        tableContent={demands}
        dataLoading={loading}
        filterKeys={['demand_crop_name', 'demand_quantity']}
      />
    </div>
  )
}

export default Demands