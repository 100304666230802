import { CAvatar } from '@coreui/react'
import React from 'react'

function ErrorView() {
  return (
    <div className='h-100vh display-flex justify-content-center align-item-center flex-direction'>
        <CAvatar className='logo-size' src='https://upjao.ai/wp-content/themes/UpjaoTheme/1x/logo.png' shape='' />
        <br></br>
        <br></br>
        <h1 className='App-color'>404</h1>
        <h1 className='App-color'>Page Not Found</h1>
    </div>
  )
}

export default ErrorView