import FarmerList from "../views/FarmerList";
import StocksList from "../views/StocksList";
import LiveAuction from "../views/LiveAuction";
import History from "../views/History";
import Traders from "../views/Traders";
import Demands from "../views/Demands";
import ReverseLiveAuction from "../views/ReverseLiveAuction";
import Invoice from "../views/Invoice";
import PurchaseOrder from "../views/PurchaseOrder";
import GenerateQRcode from "../views/GenerateQRcode";

const navItems = [
    // Farmer Router
    { path: '/farmers', exact: true, name: 'Framers List', element: FarmerList },
    // Traders Router
    { path: '/traders', exact: true, name: 'Traders List', element: Traders },
    // Stock Router
    { path: '/stocks', exact: true, name: 'Stocks List', element: StocksList },
    // Demands Router
    { path: '/demands', exact: true, name: 'Demand List', element: Demands },
    // Live Auction Router
    { path: '/liveAuction', exact: true, name: 'Live Auction', element: LiveAuction },
    // Reverse Live Auction Router
    { path: '/reverseLiveAuction', exact: true, name: 'Reverse Live Auction', element: ReverseLiveAuction },
    // History Router
    { path: '/history', exact: true, name: 'History', element: History },
    // Invoice
    { path: '/invoice', exact: true, name: 'Invoice', element: Invoice },
    // Purchase order
    { path: '/purchaseOrder', exact: true, name: 'Purchase Order', element: PurchaseOrder },
    // Generate QR code
    { path: '/generateQrCode', exact: true, name: 'Generate QR Code', element: GenerateQRcode },

]

export { navItems };