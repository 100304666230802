import './App.css';
import { BrowserRouter } from 'react-router-dom';
import '@coreui/coreui/dist/css/coreui.min.css';
import './i18n'
import AppRoute from './routes/AppRoute';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppRoute/>
      </BrowserRouter>
    </div>
  );
}

export default App; 
