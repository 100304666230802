const MANN = 20;
export const unitQuantityConversion = (conversion, unit, quantity) => {
    let convertedQuantity = quantity;
    switch (conversion) {
        case 'Mann':
            switch (unit) {
                case 'KG':
                    convertedQuantity = quantity / MANN; // 1 Mann = 20 KG
                    break;
                case 'Quintal':
                    convertedQuantity = quantity * 100 / MANN; // 1 Quintal = 100 KG
                    break;
                case 'Tonne':
                    convertedQuantity = quantity * 1000 / MANN; // 1 Tonne = 1000 KG
                    break;
                default:
                    // console.error('1.Error mann');
                    break;
            }
            break;
        case 'KG':
            switch (unit) {
                case 'Mann':
                    convertedQuantity = quantity * MANN; // 1 Mann = 20 KG
                    break;
                case 'Quintal':
                    convertedQuantity = quantity * 100; // 1 Quintal = 100 KG
                    break;
                case 'Tonne':
                    convertedQuantity = quantity * 1000; // 1 Tonne = 1000 KG
                    break;
                default:
                    // console.error('1.Error Kg');
                    break;
            }
            break;
        case 'Quintal':
            switch (unit) {
                case 'Mann':
                    convertedQuantity = quantity * MANN / 100; // 1 Quintal = 100 KG
                    break;
                case 'KG':
                    convertedQuantity = quantity / 100; // 1 Quintal = 100 KG
                    break;
                case 'Tonne':
                    convertedQuantity = quantity * 10; // 1 Tonne = 10 Quintal
                    break;
                default:
                    // console.error('1.Error quintal');
                    break;
            }
            break;
        case 'Tonne':
            switch (unit) {
                case 'Mann':
                    convertedQuantity = quantity * MANN / 1000; // 1 Tonne = 1000 KG
                    break;
                case 'KG':
                    convertedQuantity = quantity / 1000; // 1 Tonne = 1000 KG
                    break;
                case 'Quintal':
                    convertedQuantity = quantity / 10; // 1 Tonne = 10 Quintal
                    break;
                default:
                    // console.error('1.Error tonne');
                    break;
            }
            break;
        default:
            console.error('Unsupported quantity conversion');
            break;
    }

    return {
        conversion: conversion,
        convertedQuantity: convertedQuantity,
    }
}