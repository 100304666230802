import React, { useEffect, useState } from 'react'
import { getBidListByAuction } from '../utils/api';
import TableView from './TableView';
import { useTranslation } from 'react-i18next';

function AuctionBidDetail(props) {
  const { changeBidView, auctionId } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [auctionBidList, setAuctionBidList] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getBidListByAuction(auctionId)
      // console.log(response.data.data, "auctionBid");
      response?.data?.data && setAuctionBidList(response.data.data)
      setLoading(false)
    })()
  }, [])

  const columns = [
    {
      name: t('Bid Id'),
      selector: row => row.bid_id ? row.bid_id : '-',
      sortable: true,
    },
    {
      name: t('Bid Price'),
      selector: row => row.bid_price ? <p className='display-inline'>&#8377; {row.bid_price} </p> : '-',
      sortable: true,
    },
    {
      name: t('Created At'),
      selector: row => row.created_at ? row.created_at : '-',
      sortable: true,
    },
  ];

  return (
    <div>
      <TableView
        tableHeading={t('List Of Auction Bid')}
        columns={columns}
        tableContent={auctionBidList}
        dataLoading={loading}
        filterKeys={['bid_by', 'bid_price']}
        isBackArrow={true}
        onArrowAction={() => changeBidView(true)}
      />
    </div>
  )
}

export default AuctionBidDetail