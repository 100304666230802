import React from "react";
import { Page, Document, StyleSheet } from "@react-pdf/renderer";
import PageHeader from "./PageHeader";
import PageFooter from './PageFooter'
import PageContent from "./PageContent";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
});

const GeneratePDF = ({ company_information, order_item_price,order_crop_detail, totalAmount, generatedBy, type }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PageHeader company_information={company_information} type={type}/>
                <PageContent order_item_price= {order_item_price} order_crop_detail={order_crop_detail} type={type} totalAmount={totalAmount} generatedBy={generatedBy}/>
                <PageFooter />
            </Page>
        </Document>
    );
};

export default GeneratePDF;