import axios from "axios";
import { auth } from "../firebase";
const url = window.config.endpoint;
const commonDBUrl = window.config.commonEndpoint

const getRefreshedTokenId = async () => {
    return new Promise(async (resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            unsubscribe();
            try {
                const refreshedToken = await user.getIdToken(true);
                resolve(refreshedToken);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    });
};

export const getAllCompanyFarmers = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/users/company`,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "sub_company_id": localStorage.getItem('company_ids').split(",")[1]
            }
        })
        return responce
    } catch (error) {
        console.error(error);
    }
}

export const getAllParentCompanyUsers = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/users/parentCompany`,
            data: {
                "parent_company_id": localStorage.getItem('company_ids').split(",")[0]
            }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getBidListByAuction = async (auction_id) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}/auctions/${auction_id}/bids`,
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getCompanyAuctionList = async (history) => {
    try {
        const token = await getRefreshedTokenId();
        const auctionUrl = localStorage.getItem('user_role') === 'PACS-Admin' ? `${url}/auctions?history=${history}` : `${url}/auctions?history=${history}`
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: auctionUrl,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "location": JSON.parse(localStorage.getItem('browserLocation')),
                "sub_company_id": localStorage.getItem('company_ids').split(",")[1]
            }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getAuctionForPurchaseOrder = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/purchase_orders`,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "location": JSON.parse(localStorage.getItem('browserLocation')),
            }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getAuctionForInvoiceOrder = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/invoice_orders`,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "location": JSON.parse(localStorage.getItem('browserLocation')),
                "sub_company_id": localStorage.getItem('company_ids').split(",")[1]
            }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getStockList = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/stocks/company`,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "location": JSON.parse(localStorage.getItem('browserLocation')),
                "sub_company_id": localStorage.getItem('company_ids').split(",")[1]
            }
        })
        return responce
    } catch (error) {
        console.log(error);
        return error
    }
}


export const getDemandList = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/demands`,
            data: { "company_id": localStorage.getItem('company_ids').split(",")[0] }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getDownloadCropScanPdfAPI = async (scanId) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${commonDBUrl}/scans/generate_scan_pdf_merged`,
            data: { "scan_id": scanId, "pdf_type": "LONG" },
            responseType: 'blob'
        })
        return responce
    } catch (error) {
        console.error(error);
    }
}

export const getPacsCompanyName = async (sub_company_id) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}/sub_company/one/${sub_company_id}`,
        })
        return response
    } catch (error) {
        console.error(error);
    }
}

// user one details
export const getUserOneDetail = async (firebase_user_id) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/user/full`,
            data: { "firebase_user_id": firebase_user_id }
        })
        return response
    } catch (error) {
        return error
    }
}

// get qr responce
export const getQrNumberResponce = async (count) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/qr_code/generate`,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "count": count,
                "sub_company_id": localStorage.getItem('company_ids').split(",")[1]
            }
        })
        return response
    } catch (error) {
        console.error(error);
    }
}

//  qrcode history
export const getQrCodeHistory = async () => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}/qr_code/history`,
            data: {
                "company_id": localStorage.getItem('company_ids').split(",")[0],
                "sub_company_id": localStorage.getItem('company_ids').split(",")[1]
            }
        })
        return response
    } catch (error) {
        console.error(error);
    }
}

// pdf serial number responce
export const getPdfSerialNumber = async (data) => {
    try {
        const token = await getRefreshedTokenId();
        // const response = await axios({
        //     headers: {
        //         "Content-Type": "application/json",
        //         'Authorization': `Bearer ${token}`
        //     },
        //     method: 'POST',
        //     // url: `${url}/qr_code/generate`,
        //     data: data
        // })
        // return response
        console.log(data);
        return 25
    } catch (error) {
        console.error(error);
    }
}