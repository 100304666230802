import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";

const DropDownWidget = ({ dropDownHeading, dropDownList }) => (
    // <CDropdown style={{backgroundColor: 'transparent' , border: '1px solid lightgray' , margin: '0px 1px', height: '35px'}}>
    //     <CDropdownToggle color="none">&nbsp; {dropDownHeading} &nbsp;</CDropdownToggle>
    //     <CDropdownMenu>
    //         {dropDownList &&  dropDownList.map((item, index) => (
    //             <CDropdownItem key={index}>{item}</CDropdownItem>
    //         ))}
    //     </CDropdownMenu>
    // </CDropdown>
    <select style={{ borderRadius:'5px', border: '1px solid lightgray' , margin: '2px 1px', height: '35px', minWidth: 150}}>
        <option selected>All</option>
        {dropDownList && dropDownList.map((item, index) => (
            <option key={index}>{item}</option>
        ))}
    </select>
)

export default DropDownWidget;