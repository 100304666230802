import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GeneratePDF from "./PdfGeneration/GeneratePDF";

const PdfConformationModel = ({ isVisible, title, fileName, company_information, order_item_price, order_crop_detail, generatedBy, totalAmount, serialNumber, type, handleClose }) => {
    const { t } = useTranslation()
    return (
        <CModal visible={isVisible} onClick={() => handleClose()}>
            <CModalHeader >
                <CModalTitle>{title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {order_crop_detail.map((item, index) => (
                    <div key={index} className="display-flex justify-between m-l-10 m-r-10">
                        <p className="color-gray">{item.name ? item.name + ':' : ' '}</p>
                        <p className="color-gray">{item.value ? ` ${item.value}` : ' NA'}</p>
                    </div>
                ))}
            </CModalBody>
            <CModalFooter>
                <CButton variant="outline" color="secondary" onClick={() => handleClose()}>{t('NO')}</CButton>
                <PDFDownloadLink document=
                    {<GeneratePDF
                        company_information={company_information}
                        order_item_price={order_item_price}
                        order_crop_detail={order_crop_detail}
                        generatedBy={generatedBy}
                        totalAmount={totalAmount}
                        serialNumber={serialNumber}
                        type={type} />
                    }
                    fileName={fileName}>
                    <CButton variant="outline" color="success" >{t('YES')}</CButton>
                </PDFDownloadLink>
            </CModalFooter>
        </CModal>
    );
}

export default PdfConformationModel;