import React from 'react'
import ChallanDownload from './PdfGeneration/ChallanDownload';
import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";

const ChallanConformationModel = ({
    isVisible,
    handleClose,
    name,
    crop,
    type,
    company_name,
    receiver_company_name,
    lot_id,
    date_of_billing,
    trader_name,
    trader_address,
    number_of_bags,
    per_bag_quantity,
    vehicle_number,
    total_quanity,
    check_stock_dispatch
}) => {
    const { t } = useTranslation()
    return (
        <CModal visible={isVisible} onClick={() => handleClose()}>
            <CModalHeader >
                <CModalTitle>{'Challan'}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p>{check_stock_dispatch
                    ? 'Do you want to download challan ?'
                    : 'Please dispatch stock from Android App and enter vehicle number at time of dispatch'}</p>
            </CModalBody>
            <CModalFooter>
                <CButton variant="outline" color="secondary" onClick={() => handleClose()}>{t('NO')}</CButton>
                <PDFDownloadLink document=
                    {<ChallanDownload
                        crop={crop}
                        type={type}
                        company_name={company_name}
                        receiver_company_name={receiver_company_name}
                        lot_id={lot_id}
                        date_of_billing={date_of_billing}
                        trader_name={trader_name}
                        trader_address={trader_address}
                        number_of_bags={number_of_bags}
                        per_bag_quantity={per_bag_quantity}
                        vehicle_number={vehicle_number}
                        total_quanity={total_quanity}
                    />}
                    fileName={`${name}_challan`}>
                    {check_stock_dispatch && <CButton variant="outline" color="success" >{t('YES')}</CButton>}
                </PDFDownloadLink>
            </CModalFooter>
        </CModal>
    );
}

export default ChallanConformationModel