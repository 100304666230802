import React, { useState, useEffect } from 'react'
import { cilArrowLeft, cilPlus, cilXCircle } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { CButton, CFormCheck } from '@coreui/react';
import InvoiceHistroyDetails from './InvoiceHistroyDetails';
import { unitQuantityConversion } from '../utils/unitQuantityConversion';
import { unitPriceConversion } from '../utils/unitPriceConversion';
import { getPdfSerialNumber } from '../utils/api';
import PdfConformationModel from '../widgets/PdfConformationModel';
import { IST_date_time } from '../utils/ist_date_time_conversion';
import ChallanConformationModel from '../widgets/ChallanConformationModel';
import { PDFViewer } from '@react-pdf/renderer'
import GeneratePDF from '../widgets/PdfGeneration/GeneratePDF';
import ChallanDownload from '../widgets/PdfGeneration/ChallanDownload';
import { useTranslation } from 'react-i18next';


function InvoiceOrderDetail(props) {
    const { changeShowDetail, winnerData } = props
    const { t } = useTranslation()
    const [showInvoiceHistroy, setShowInvoiceHistroy] = useState(true)
    const [unitConversion, setUnitConversion] = useState('Mann')
    const [pacsAddress, setPacsAddress] = useState('')
    const [farmerAddress, setFarmerAddress] = useState('')
    const [apmcAddress, setApmcAddress] = useState('')
    const [billing_date, set_billing_date] = useState()
    const [actual_quanity, set_actual_quality] = useState('')
    const [number_of_bags, set_number_of_bags] = useState('')
    const [weight_per_bag, set_weight_per_bag] = useState(0)
    const [weight_type, set_weight_type] = useState('gram')
    const [quantity_adjustment, set_quantity_adjustment] = useState(0)
    const [lot_cost, set_lot_cost] = useState(0)
    const [apmc_market_fee_input, set_apmc_market_fee_input] = useState(0)
    const [pacs_commission_input, set_pacs_commission_input] = useState(0)
    const [loading_cost_input, set_loading_cost_input] = useState(0)
    const [unloading_cost_input, set_unloading_cost_input] = useState(0)
    const [transport_cost, set_transport_cost] = useState(0)
    const [isSubtractBagWeight, setIsSubtractBagWeight] = useState(false)
    const [isUpdateActualQuantity, setIsUpdateActualQuantity] = useState(false)
    const [base_price, set_base_price] = useState(winnerData.auction_info.base_price)
    const [bid_winner_price, set_bid_winner_price] = useState(winnerData.auction_info.final_price)
    const [estimated_quantity, set_estimated_quantity] = useState(winnerData.stock_info.stock_quantity)
    const [apmc_percentage, set_apmc_percentage] = useState(0)
    const [pacs_percentage, set_pacs_percentage] = useState(0)
    const [total_loading_cost, set_total_loading_cost] = useState(0)
    const [total_unloading_cost, set_total_unloading_cost] = useState(0)
    const [userEnteredAdditionalPriceWithTitle, setUserEnteredAdditionalPriceWithTitle] = useState({})
    const [additialTitle, setAdditionalTitle] = useState('')
    const [additialPrice, setAdditionalPrice] = useState('')
    const [sumOfAdditionalPrices, setSumOfAdditionalPrices] = useState(0)
    const [invoiceOrderNumber, setInvoiceOrderNumber] = useState('')
    const [isPdfConformationModel, setIsPdfConformationModel] = useState(false);
    const [isChallanConformationModel, isSetChallanConformationModel] = useState(false);
    const [total_actual_qty_with_bags, set_total_actual_qty_with_bags] = useState(0)


    // initally all data in mann
    useEffect(() => {
        const base_unit = unitQuantityConversion('Mann', winnerData.auction_info.base_price_unit, winnerData.stock_info.stock_quantity)
        set_estimated_quantity(base_unit.convertedQuantity)
        set_actual_quality(base_unit.convertedQuantity)
        // setTotalWeightWithBag(base_unit.convertedQuantity)
        const base_price = unitPriceConversion('Mann', winnerData.auction_info.base_price_unit, winnerData.auction_info.base_price)
        set_base_price(base_price.convertedPrice)
        const bid_winner_price = unitPriceConversion('Mann', winnerData.auction_info.base_price_unit, winnerData.auction_info.final_price)
        set_bid_winner_price(bid_winner_price.convertedPrice)
        calculateLotCostAndAdjustmentQuanlity()
    }, [])

    useEffect(() => {
        calculationPerActualQuanity(actual_quanity)
        // calculateLotCostAndAdjustmentQuanlity()
    }, [isSubtractBagWeight])

    useEffect(() => {
        const base_unit = unitQuantityConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.stock_info.stock_quantity)
        set_estimated_quantity(base_unit.convertedQuantity)
        set_actual_quality(base_unit.convertedQuantity)
        // setTotalWeightWithBag(base_unit.convertedQuantity)
        const base_price = unitPriceConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.auction_info.base_price)
        set_base_price(base_price.convertedPrice)
        const bid_winner_price = unitPriceConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.auction_info.final_price)
        set_bid_winner_price(bid_winner_price.convertedPrice)
        clearData()
        calculateLotCostAndAdjustmentQuanlity()
    }, [unitConversion])

    const order_crop_detail = [
        { name: 'Lot id', value: `${winnerData.stock_info.lot_id}` },
        { name: 'Crop', value: `${winnerData.stock_info.stock_crop_name}`, },
        { name: 'Variety', value: `${winnerData.stock_info.stock_crop_variety !== 'default' ? winnerData.stock_info.stock_crop_variety : '--'}`, },
        { name: 'Actual Quantity', value: `${actual_quanity} ${t(unitConversion)}`, },
        { name: 'Number Of Bags', value: number_of_bags },
        { name: 'Weight Of One Empty Bag', value: weight_per_bag && weight_type ? `${weight_per_bag} ${weight_type}` : '' },
        { name: 'Adjustment Quantity', value: `${quantity_adjustment.toFixed(2)} ${t(unitConversion)}` },
    ]

    let order_item_price = [
        { name: `${t('Base Price')} (${t('Per')} ${t(unitConversion)})`, value: winnerData.auction_info.base_price && Number(base_price).toFixed(2) },
        { name: `${t('Bid Winning Price')} (${t('Per')} ${t(unitConversion)})`, value: bid_winner_price ? bid_winner_price.toFixed(2) : 0 },
        { name: 'Lot Cost', value: lot_cost && Number(lot_cost).toFixed(2) },
        { name: 'APMC Market Fee', value: apmc_percentage && Number(apmc_percentage).toFixed(2) },
        { name: 'PACS Commission', value: pacs_percentage && Number(pacs_percentage).toFixed(2) },
        { name: 'Loading Cost', value: total_loading_cost && Number(total_loading_cost).toFixed(2) },
        { name: 'Unloading Cost', value: total_unloading_cost && Number(total_unloading_cost).toFixed(2) },
        { name: 'Transport Cost', value: transport_cost && Number(transport_cost).toFixed(2) },
    ]

    const mergedItems = Object.entries(userEnteredAdditionalPriceWithTitle).map(([key, value]) => ({
        name: key,
        value: value,
    }));
    order_item_price = [...order_item_price, ...mergedItems]

    const company_information = [
        {
            'Farmer': `${winnerData.winning_farmer}`,
            "Address": farmerAddress
        },
        {
            "PACS": localStorage.getItem('company_detail').split(",")[1],
            "Address": pacsAddress
        },
        {
            "APMC": winnerData.trader_sub_company,
            "Address": apmcAddress
        },
        {
            'Purchaser': `${winnerData.winning_trader}`,
            "Address": winnerData.trader_sub_company,
        },
    ]

    const totalAmount = Number(Number(lot_cost) + Number(apmc_percentage) + Number(pacs_percentage) + parseInt(total_loading_cost) + parseInt(total_unloading_cost) + parseInt(transport_cost) + Number(sumOfAdditionalPrices)).toFixed(2)
    const generatedBy = { generatedByName: localStorage.getItem('company_detail').split(",")[0], billing_date: billing_date }

    // select unit type
    const onSelectUnitType = (event) => {
        setUnitConversion(event.target.value)
        setIsUpdateActualQuantity(false)
    }

    const onUpdateActualQuantity = () => {
        setIsUpdateActualQuantity(!isUpdateActualQuantity)
        const base_unit = unitQuantityConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.stock_info.stock_quantity)
        set_actual_quality(base_unit.convertedQuantity)
        calculateLotCostAndAdjustmentQuanlity()
        clearData()
    }

    const onHandleActualQuantityUserInput = (user_actual_quanity) => {
        clearCommissionData()
        set_actual_quality(user_actual_quanity)
        calculationPerActualQuanity(user_actual_quanity)
    }

    const onWeightOfEachBag = () => {
        const weightPerBag = weight_per_bag
        let updatedWeightPerBag
        switch (unitConversion) {
            case 'Mann':
                updatedWeightPerBag = weightPerBag / (20 * 1000); // Convert grams to Mann
                break;
            case 'Quintal':
                updatedWeightPerBag = weightPerBag / 100000; // Convert grams to Quintal
                break;
            case 'Tonne':
                updatedWeightPerBag = weightPerBag / 1000000; // Convert grams to Ton
                break;
            case 'KG':
                updatedWeightPerBag = weightPerBag / 1000; // Convert grams to KG
                break;
            default:
                break;
        }
        return updatedWeightPerBag;
    }

    // calculate all cost like lotcost , adjustment quanlity
    const calculateLotCostAndAdjustmentQuanlity = () => {
        clearCommissionData()
        const convertedQuantity = unitQuantityConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.stock_info.stock_quantity).convertedQuantity
        const base_price = unitPriceConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.stock_info.stock_quantity).convertedPrice
        const bid_winner_price = unitPriceConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.auction_info.final_price).convertedPrice
        onSetValuesAccordingToFormulas(convertedQuantity, base_price, bid_winner_price)
    }

    const calculationPerActualQuanity = (user_actual_quanity) => {
        clearCommissionData()
        const convertedQuantity = unitQuantityConversion(unitConversion, unitConversion, user_actual_quanity).convertedQuantity
        const base_price = unitPriceConversion(unitConversion, unitConversion, user_actual_quanity).convertedPrice
        const bid_winner_price = unitPriceConversion(unitConversion, winnerData.auction_info.base_price_unit, winnerData.auction_info.final_price).convertedPrice
        onSetValuesAccordingToFormulas(convertedQuantity, base_price, bid_winner_price)
    }

    const onSetValuesAccordingToFormulas = (convertedQuantity, base_price, bid_winner_price) => {
        let convertedWeightPerBag = onWeightOfEachBag()

        //adjustmentedQuantity = quanity - (weightPerBag * no of bags)
        convertedWeightPerBag = isSubtractBagWeight ? convertedWeightPerBag : 0
        const weightOfEmptyBags = number_of_bags * convertedWeightPerBag
        const adjustmentedQuantity = convertedQuantity - weightOfEmptyBags

        // console.log(convertedQuantity - weightOfEmptyBags, convertedQuantity, weightOfEmptyBags);
        // console.log(`stock_quantity: ${winnerData.stock_info.stock_quantity} convertedQuantity: ${convertedQuantity}, conversion: ${conversion}, ${weightOfEmptyBags}, ${adjustmentedQuantity}`);

        set_quantity_adjustment(adjustmentedQuantity)
        const lot_cost = adjustmentedQuantity * bid_winner_price
        set_lot_cost(lot_cost)
        set_bid_winner_price(bid_winner_price)
        set_base_price(base_price)

        set_total_actual_qty_with_bags(convertedQuantity + number_of_bags * convertedWeightPerBag)
    }

    const onWantToSubtractBagWeight = () => {
        setIsSubtractBagWeight(!isSubtractBagWeight)
        set_weight_per_bag(0)
        set_apmc_market_fee_input(0)
        set_apmc_percentage(0)
        set_pacs_commission_input(0)
        set_pacs_percentage(0)
        set_loading_cost_input(0)
        set_total_loading_cost(0)
        set_unloading_cost_input(0)
        set_total_unloading_cost(0)
    }

    const onCalculateApmcMarketFee = (apmc_percentage) => {
        set_apmc_market_fee_input(apmc_percentage)
        const commission = lot_cost * apmc_percentage / 100;
        set_apmc_percentage(commission ? commission : '')
    }

    const onCalculatePacsCommission = (pacs_percentage) => {
        set_pacs_commission_input(pacs_percentage)
        const commission = lot_cost * pacs_percentage / 100;
        set_pacs_percentage(commission ? commission : '')
    }

    const onCalculateLoadingCost = (loading_value) => {
        set_loading_cost_input(loading_value)
        const cost = number_of_bags * loading_value
        set_total_loading_cost(cost)
    }

    const onCalculateUnloadingCost = (unloading_value) => {
        set_unloading_cost_input(unloading_value)
        const cost = number_of_bags * unloading_value
        set_total_unloading_cost(cost)
    }

    const onInsertAdditionlPrice = () => {
        const updatedAdditionalValues = { ...userEnteredAdditionalPriceWithTitle };
        if (additialPrice && additialTitle) {
            updatedAdditionalValues[additialTitle] = additialPrice
        }
        setSumOfAdditionalPrices(sumOfAdditionalPrices + Number(additialPrice))
        setUserEnteredAdditionalPriceWithTitle(updatedAdditionalValues);
        setAdditionalPrice('')
        setAdditionalTitle('')
    }

    const onRemoveAdditionlPrice = (key) => {
        const updatedAdditionalValues = { ...userEnteredAdditionalPriceWithTitle };
        setSumOfAdditionalPrices(sumOfAdditionalPrices - Number(updatedAdditionalValues[key]))
        delete updatedAdditionalValues[key]
        setUserEnteredAdditionalPriceWithTitle(updatedAdditionalValues);
    }

    const invoiceOrderResponce = async () => {
        const invoice_details = {
            auction_id: winnerData.auction_info.auction_id,
            apmcAddress: apmcAddress,
            traderAddress: farmerAddress,
            pacsAddress: pacsAddress,
            dateOfBiling: billing_date,
            noOfBags: number_of_bags,
            weightPerbags: weight_per_bag,
            unitPerEmptyBag: 'gram',
            apmcMarketfee: apmc_percentage,
            pacsCommission: pacs_percentage,
            loadingCost: total_loading_cost,
            unloadingCost: total_unloading_cost
        }
        setIsPdfConformationModel(!isPdfConformationModel)
        const serialNumber = await getPdfSerialNumber(invoice_details)
        setInvoiceOrderNumber(serialNumber)
        console.log(serialNumber);
    }


    const clearData = () => {
        clearCommissionData()
        set_number_of_bags(0)
        set_weight_per_bag(0)
        setIsSubtractBagWeight(false)
        set_transport_cost(0)
    }

    const clearCommissionData = () => {
        set_apmc_market_fee_input(0)
        set_apmc_percentage(0)
        set_pacs_commission_input(0)
        set_pacs_percentage(0)
        set_loading_cost_input(0)
        set_total_loading_cost(0)
        set_unloading_cost_input(0)
        set_total_unloading_cost(0)
    }


    return (
        <div>
            {showInvoiceHistroy ? <div>
                <div className='sticky'>
                    <div className='display-flex justify-content-between pt-1 p-r-10 p-l-10'>
                        <h6 className='pt-1 p-l-5'><CIcon className="cursor" icon={cilArrowLeft} onClick={() => changeShowDetail(true)} /></h6>
                        <h5>{t('Invoice')}</h5>
                        <h6 className='pt-1 p-r-5 cursor' onClick={() => setShowInvoiceHistroy(false)}>{t('Invoice Histroy')}</h6>
                    </div>
                </div>

                <div>
                    {invoiceOrderNumber && <PdfConformationModel
                        isVisible={isPdfConformationModel}
                        title={'Invoice'}
                        fileName={`${winnerData.winning_farmer}_Invoice`}
                        company_information={company_information}
                        order_item_price={order_item_price}
                        order_crop_detail={order_crop_detail}
                        generatedBy={generatedBy}
                        totalAmount={totalAmount}
                        serialNumber={invoiceOrderNumber}
                        type='invoice'
                        handleClose={() => setIsPdfConformationModel(!isPdfConformationModel)}
                    />}

                    <ChallanConformationModel
                        isVisible={isChallanConformationModel}
                        handleClose={() => isSetChallanConformationModel(!isChallanConformationModel)}
                        name={winnerData.winning_farmer}
                        crop={winnerData.stock_info.stock_crop_name}
                        company_name={localStorage.getItem('company_detail').split(",")[1]}
                        receiver_company_name={winnerData.trader_sub_company}
                        lot_id={winnerData.stock_info.lot_id}
                        date_of_billing={billing_date ? billing_date : ''}
                        trader_name={winnerData.winning_trader}
                        trader_address={winnerData.trader_sub_company}
                        number_of_bags={number_of_bags}
                        per_bag_quantity={number_of_bags && (total_actual_qty_with_bags / number_of_bags).toFixed(3)}
                        total_quanity={`${total_actual_qty_with_bags} ${unitConversion}`}
                        vehicle_number={winnerData?.stock_info?.stock_metadata ? winnerData?.stock_info?.stock_metadata[0]?.Value : "NA"}
                        check_stock_dispatch={winnerData.stock_info.stock_dispatched ? winnerData.stock_info.stock_dispatched : false}
                    />

                    {/* <PDFViewer width="1000" height="600" className="app" >
                        <ChallanDownload
                            isVisible={isChallanConformationModel}
                            handleClose={() => isSetChallanConformationModel(!isChallanConformationModel)}
                            name={winnerData.winning_farmer}
                            crop={winnerData.stock_info.stock_crop_name}
                            company_name={localStorage.getItem('company_detail').split(",")[1]}
                            receiver_company_name={winnerData.trader_sub_company}
                            lot_id={winnerData.stock_info.lot_id}
                            date_of_billing={billing_date ? billing_date : ''}
                            trader_name={traderDetails.first_name + ' ' + traderDetails.last_name}
                            trader_address={winnerData.trader_sub_company}
                            number_of_bags={number_of_bags}
                            per_bag_quantity={number_of_bags && (total_actual_qty_with_bags / number_of_bags).toFixed(3)}
                            total_quanity={`${total_actual_qty_with_bags} ${unitConversion}`}
                            vehicle_number={winnerData?.stock_info?.stock_metadata ? winnerData?.stock_info?.stock_metadata[0]?.Value : "NA"}
                            check_stock_dispatch={winnerData.stock_info.stock_dispatched ? winnerData.stock_info.stock_dispatched : false}
                        />
                    </PDFViewer> */}
                </div>


                <main style={{ textAlign: 'left', marginLeft: '20px' }}>

                    {/* Unit Conversion drop down  */}
                    <div>
                        <p style={{ padding: 10, border: '1px solid lightgray', borderRadius: '10px' }}><b>{t('Select Base Unit')}: </b> &nbsp;
                            <select
                                onChange={onSelectUnitType}
                                style={{ borderRadius: '10px', border: '1px solid green', padding: '0px 2px' }}
                            >
                                <option value='Mann'>{t('Mann')}</option>
                                <option value='KG' >{t('KG')}</option>
                                <option value='Quintal'>{t('Quintal')}</option>
                                <option value='Tonne' >{t('Tonne')}</option>
                            </select></p>
                    </div>

                    {/* pacs and Farmer */}
                    <div>
                        <div className='display-flex justify-content-around'>
                            <div className='w-50per'>
                                <h5 className='fw-700'>{t('PACS Detail')}</h5>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Name')}:</td>
                                            <td><p style={{ margin: '0px', marginLeft: '10px' }}>{localStorage.getItem('company_detail').split(",")[1]}</p></td>
                                        </tr>
                                        <tr style={{ marginTop: 10 }}>
                                            <td><p className='fw-500 color-gray m-t-10'>{t('Address')}:</p></td>
                                            <td><p style={{ margin: '0px', marginLeft: '10px' }}>
                                                <input
                                                    name='pasc_address'
                                                    style={{ margin: '0px' }}
                                                    type='text'
                                                    placeholder={`${t("PACS's")} ${t('Address')}`}
                                                    onChange={(e) => setPacsAddress(e.target.value)}
                                                /></p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='w-50per' style={{ borderLeft: '1px solid lightgray' }}>
                                <div style={{ marginLeft: '10px' }}>
                                    <h5 className='fw-700'>{t('Farmer Detail')}</h5>
                                    <tbody>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Name')}:</td>
                                            <td><p style={{ margin: '0px', marginLeft: '10px' }}>{winnerData?.winning_farmer}</p></td>
                                        </tr>
                                        <tr style={{ marginTop: 10 }}>
                                            <td><p className='fw-500 color-gray m-t-10'>{t('Address')}:</p></td>
                                            <td><p style={{ margin: '0px', marginLeft: '10px' }}>
                                                <input
                                                    name='farmer_address'
                                                    style={{ margin: '0px' }}
                                                    type='text'
                                                    placeholder={`${t("Farmer's")} ${t('Address')}`}
                                                    onChange={(e) => setFarmerAddress(e.target.value)}
                                                /></p></td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                        </div>

                        <hr style={{ margin: '0px' }}></hr>

                        <div className='display-flex justify-content-around'>
                            <div className='w-50per'>
                                <h5 className='fw-700'>{t('Lot Detail')}</h5>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><span className='fw-500 color-gray'>{t('Lot id')}:</span></td>
                                            <td>{winnerData.stock_info.lot_id}</td>
                                        </tr>
                                        <tr>
                                            <td><span className='fw-500 color-gray'>{t('Crop')}:</span></td>
                                            <td>{winnerData.stock_info.stock_crop_name} {winnerData.stock_info.stock_crop_variety !== 'default' && winnerData.stock_info.stock_crop_variety}</td>
                                        </tr>
                                        <tr>
                                            <td><span className='fw-500 color-gray'>{t('Farmer ID')}:</span></td>
                                            <td>{winnerData?.user_id ? winnerData?.user_id : '--'}</td>
                                        </tr>
                                        <tr>
                                            <td><span className='fw-500 color-gray'>{t('Estimated Quantity')}:</span></td>
                                            <td>{estimated_quantity} {t(unitConversion)}</td>
                                        </tr>
                                        <tr>
                                            <td><span className='fw-500 color-gray'>{t('Date of trade')}:</span></td>
                                            <td>{IST_date_time(winnerData.auction_info.end_time)}</td>
                                        </tr>
                                        <tr>
                                            <td><span className='fw-500 color-gray'>{t('Base Price')}:</span></td>
                                            <td><b>&#8377; </b> {winnerData.auction_info.base_price} {t('Per')} {t(winnerData.auction_info.base_price_unit)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='w-50per' style={{ borderLeft: '1px solid lightgray' }}>
                                <div style={{ marginLeft: '10px' }}>
                                    <h5 className='fw-700'>{t('APMC Detail')}</h5>
                                    <tbody>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Name')}:</td>
                                            <td><p style={{ margin: '0px', marginLeft: '10px' }}>{winnerData.trader_sub_company}</p></td>
                                        </tr>
                                        <tr style={{ marginTop: 10 }}>
                                            <td><p className='fw-500 color-gray m-t-10'>{t('Address')}:</p></td>
                                            <td><p style={{ margin: '0px', marginLeft: '10px' }}>
                                                <input
                                                    name='apmc_address'
                                                    style={{ margin: '0px' }}
                                                    type='text'
                                                    placeholder={`${t("APMC's")} ${t('Address')}`}
                                                    onChange={(e) => setApmcAddress(e.target.value)} />
                                            </p></td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                        </div>

                        <hr style={{ margin: '0px' }}></hr>

                        <div>
                            <div className='w-100per'>
                                <h5 className='fw-700'>{t('Additional Detail')}</h5>
                                <table className='w-100per'>
                                    <tbody>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Date Of Billing')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='billing_date'
                                                    style={{ margin: '0px', width: 190 }}
                                                    type='date'
                                                    value={billing_date}
                                                    onChange={(e) => set_billing_date(e.target.value)}
                                                />
                                            </p></td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Lot id')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>{winnerData.stock_info.lot_id}</p></td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Bid Winning Price')}</td>
                                            <td>
                                                <p style={{ marginBottom: '0.5rem' }}><b>&#8377; {bid_winner_price} {t('Per')} {t(unitConversion)}</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Do you want to update actual quantity ?')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <CFormCheck
                                                    style={{ cursor: 'pointer' }}
                                                    className='fw-500 color-gray'
                                                    checked={isUpdateActualQuantity}
                                                    onChange={onUpdateActualQuantity}
                                                /></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Actual Quantity')}</td>
                                            <td>
                                                <p style={{ marginBottom: '0.5rem' }}>
                                                    <input
                                                        name='actualQuanity'
                                                        type='number'
                                                        disabled={!isUpdateActualQuantity}
                                                        value={actual_quanity}
                                                        style={{ margin: '0px' }}
                                                        onChange={(e) => onHandleActualQuantityUserInput(e.target.value)}
                                                    />{' '}
                                                    {t(unitConversion)}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Number Of Bags')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='number_of_bags'
                                                    type='number'
                                                    value={number_of_bags ? number_of_bags : ''}
                                                    placeholder={`${t('Number Of Bags')}`}
                                                    onBlur={isUpdateActualQuantity ? () => calculationPerActualQuanity(actual_quanity) : calculateLotCostAndAdjustmentQuanlity}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => set_number_of_bags(e.target.value)}
                                                /></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Do you want to subtract bag weight ?')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <CFormCheck
                                                    style={{ cursor: 'pointer' }}
                                                    className='fw-500 color-gray'
                                                    checked={isSubtractBagWeight}
                                                    onChange={onWantToSubtractBagWeight}
                                                /></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Weight Of One Empty Bag')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='weight_per_bag'
                                                    type='number'
                                                    value={weight_per_bag ? weight_per_bag : ''}
                                                    placeholder={`${t('Weight per bag')}`}
                                                    onBlur={isUpdateActualQuantity ? () => calculationPerActualQuanity(actual_quanity) : calculateLotCostAndAdjustmentQuanlity}
                                                    disabled={!isSubtractBagWeight}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => set_weight_per_bag(e.target.value)}
                                                />{' '}
                                                <select onChange={(e) => set_weight_type(e.target.value)} name='weight_type' disabled>
                                                    <option value={'gram'}>gm</option>
                                                </select>
                                            </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Adjustment Quantity')}</td>
                                            <td>
                                                <p style={{ marginBottom: '0.5rem' }}><b>{quantity_adjustment.toFixed(2)} {t(unitConversion)}</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Lot Cost')}</td>
                                            <td>
                                                <p style={{ marginBottom: '0.5rem' }}><b>&#8377; {lot_cost.toFixed(2)}</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('APMC Market Fee')} ({t('In Percentage')})</td>
                                            <td> <p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='apmc_market_fee'
                                                    type='number'
                                                    value={apmc_market_fee_input ? apmc_market_fee_input : ''}
                                                    placeholder={`${t('APMC Market Fee')}`}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => onCalculateApmcMarketFee(e.target.value)}
                                                />{' '}
                                                {apmc_percentage ? <b>&#8377; {apmc_percentage.toFixed(2)}</b> : ''}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('PACS Commission')} ({t('In Percentage')})</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='pacs_commission'
                                                    type='number'
                                                    value={pacs_commission_input ? pacs_commission_input : ''}
                                                    placeholder={`${t('PACS Commission')}`}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => onCalculatePacsCommission(e.target.value)}
                                                />{' '}
                                                {pacs_percentage ? <b>&#8377; {pacs_percentage.toFixed(2)}</b> : ''}
                                            </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Loading Cost')} ({t('Per Bag')})</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='loading_cost'
                                                    type='number'
                                                    value={loading_cost_input ? loading_cost_input : ''}
                                                    placeholder={`${t('Loading Cost')}`}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => onCalculateLoadingCost(e.target.value)}
                                                />{' '}
                                                {total_loading_cost ? <b>&#8377; {total_loading_cost.toFixed(2)}</b> : ''}
                                            </p> </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Unloading Cost')} ({t('Per Bag')})</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='unloading_cost'
                                                    type='number'
                                                    value={unloading_cost_input ? unloading_cost_input : ''}
                                                    placeholder={`${t('Unloading Cost')}`}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => onCalculateUnloadingCost(e.target.value)}
                                                />{' '}
                                                {total_unloading_cost ? <b>&#8377; {total_unloading_cost.toFixed(2)}</b> : ''}
                                            </p> </td>
                                        </tr>
                                        <tr>
                                            <td className='fw-500 color-gray'>{t('Transport Cost')}</td>
                                            <td><p style={{ marginBottom: '0.5rem' }}>
                                                <input
                                                    name='transport_cost'
                                                    type='number'
                                                    value={transport_cost ? transport_cost : ''}
                                                    placeholder={`${t('Transport Cost')}`}
                                                    style={{ margin: '0px' }}
                                                    onChange={(e) => set_transport_cost(e.target.value)}
                                                />{' '}
                                                {transport_cost ? <b>&#8377; {transport_cost}</b> : ''}
                                            </p></td>
                                        </tr>
                                        <>
                                            {userEnteredAdditionalPriceWithTitle && Object.entries(userEnteredAdditionalPriceWithTitle).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td className='fw-500 color-gray'>{key}</td>
                                                    <td className='fw-500 color-gray'><b>&#8377; {value}</b> <span className='color-red cursor' onClick={() => onRemoveAdditionlPrice(key)}><CIcon icon={cilXCircle} /></span></td>
                                                </tr>
                                            ))}
                                        </>

                                        <tr>
                                            <td><p style={{ marginBottom: '0.5rem' }}><input type='text' value={additialTitle} placeholder={`${(t('Enter Additional Type'))}`} onChange={(e) => setAdditionalTitle(e.target.value)} /></p></td>
                                            <td><p style={{ marginBottom: '0.5rem' }}><input type='number' value={additialPrice} placeholder={`${(t('Enter Additional Price'))}`} onChange={(e) => setAdditionalPrice(e.target.value)} />
                                                <button className='m-l-10 color-green b-a-1px' onClick={onInsertAdditionlPrice}><CIcon icon={cilPlus} />{t('Add')}</button>
                                            </p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div className='display-flex justify-content-end' style={{ marginTop: 10, paddingRight: "10px", paddingBottom: "10px" }}>
                        {<CButton style={{ marginRight: 10 }} onClick={() => isSetChallanConformationModel(!isChallanConformationModel)}>{t('Generate Challan')}</CButton>}
                        {<CButton onClick={invoiceOrderResponce}>{t('Generate Order')}</CButton>}
                    </div>
                    <br></br>
                </main>
            </div> : <InvoiceHistroyDetails backToInvoiceOrderDetail={setShowInvoiceHistroy} />}
        </div>
    )
}

export default InvoiceOrderDetail