import React, { useEffect, useState } from 'react'
import TableView from './TableView';
import { getCompanyAuctionList, getDownloadCropScanPdfAPI } from '../utils/api';
import { CButton, CSpinner } from '@coreui/react';
import AuctionBidDetail from './AuctionBidDetail';
import { useTranslation } from 'react-i18next'
import { downloadCropScanPdf } from '../utils/downloadPDF';
import { BsFileEarmarkPdfFill } from 'react-icons/bs'
import { IST_date_time } from '../utils/ist_date_time_conversion';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { onAttachedReverseGeoLocationAuction } from '../utils/common';
import { unitQuantityConversion } from '../utils/unitQuantityConversion';
import { unitPriceConversion } from '../utils/unitPriceConversion';

function History() {
  const MANN = 20;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [showBid, setShowBid] = useState(true)
  const [auctionId, setAuctionId] = useState('')
  const [conversion, setConversion] = useState('Mann')
  const [dateRange, setDateRange] = useState()
  const [isShowDownloaded, setIsShowDownloaded] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [tooltip_content, setTooltipContent] = useState('')


  const dataAccordingToDate = (startDateString, endDateString) => {
    const startDate = new Date(startDateString.replace(/-/g, '/'));
    const endDate = new Date(endDateString.replace(/-/g, '/'));
    endDate.setDate(endDate.getDate() + 1);

    const filteredData = history.filter(item => {
      const itemStartDate = new Date(item.auction_info.start_time.replace(/-/g, '/'));
      const itemEndDate = new Date(item.auction_info.closed_at.replace(/-/g, '/'));
      return itemStartDate >= startDate && itemEndDate <= endDate;
    });
    setFilterData(filteredData)
  }

  useEffect(() => {
    if (dateRange && dateRange.length === 2) {
      dataAccordingToDate(dateRange[0].format(), dateRange[1].format())
    } else {
      setFilterData(history)
    }
  }, [dateRange])

  useEffect(() => {
    (async () => {
      const response = await getCompanyAuctionList(true)
      try {
        if (response?.data?.data) {
          const historyList = await Promise.all(response.data.data.map(async (item) => {
            const weight_conversion = unitQuantityConversion("Mann", item.stock_info.stock_unit, item.stock_info.stock_quantity)
            const price_conversion = unitPriceConversion("Mann", item.stock_info.stock_unit, item.max_bid.bid_price)
            item.stock_info.stock_unit = weight_conversion.conversion;
            item.stock_info.stock_quantity = weight_conversion.convertedQuantity;
            // item.stock_price_unit = price_conversion.conversion;
            item.max_bid.bid_price = price_conversion?.convertedPrice;
            return item
          }))
          const final_list = await onAttachedReverseGeoLocationAuction(historyList, 'stock_info.stock_location')
          setHistory(final_list);
          setFilterData(final_list);
        } else {
          setHistory([]);
          setFilterData([])
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)
      }
    })()
  }, [showBid])

  const onHandleUnitConversion = async (event) => {
    const value = event.target.value
    const array = await Promise.all(history.map(async (item) => {
      const weight_conversion = unitQuantityConversion(value, item.stock_info.stock_unit, item.stock_info.stock_quantity)
      const price_conversion = unitPriceConversion(value, item.stock_info.stock_unit, item.max_bid.bid_price)
      item.stock_info.stock_unit = weight_conversion.conversion;
      item.stock_info.stock_quantity = weight_conversion.convertedQuantity;
      // item.stock_price_unit = price_conversion.conversion;
      item.max_bid.bid_price = price_conversion?.convertedPrice;
      return item
    }))
    setConversion(value);
    setHistory(array);
    setFilterData(array)
  }

  const columns = [
    {
      name: <p className='m-0 p-0 fw-bold text-align-center'>{t('Lot id')}</p>,
      width: '4.5rem',
      selector: row => row.stock_info.lot_id ? row.stock_info.lot_id : '-',
      sortable: true
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Farmer ID')}</p>,
      width: '6.5rem',
      selector: row => row.user_id ? row.user_id : '-',
      sortable: true
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Crop')}</p>,
      width: '7rem',
      selector: row => row.stock_info.stock_crop_name ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(`${row.stock_info.stock_crop_name} ${row.stock_info.stock_crop_variety !== "default" ? `(${row.stock_info.stock_crop_variety})` : ''}`)}>
        {row.stock_info.stock_crop_name} {row.stock_info.stock_crop_variety !== "default" ? `(${row.stock_info.stock_crop_variety})` : ''}</p> : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='p-0 m-0 fw-bold'>{t('Quantity')}</p>
        <select onChange={onHandleUnitConversion}
          style={{ borderRadius: '10px', border: '1px solid green', padding: '0px 2px', margin: '3px 0px' }}
        >
          <option value='Mann'>{t('Mann')}</option>
          <option value='KG'>{t('KG')}</option>
          <option value='Quintal'>{t('Quintal')}</option>
          <option value='Tonne'>{t('Tonne')}</option>
        </select>
      </div>,
      width: '5.5rem',
      selector: row => row.stock_info.stock_quantity ? (row.stock_info.stock_quantity).toFixed(2) : '-',
      // sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Address')}</p>,
      width: '7rem',
      selector: row => row?.stock_info?.stock_location ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row?.stock_info?.location_data?.display_name)}>{row?.stock_info?.location_data?.display_name}</p> : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='p-0 m-0 fw-bold'>{t('Quality')}</p>
        <p className='p-0 m-0 fw-bold'>{t('Score')}</p>
      </div>,
      width: '5rem',
      selector: row => row.stock_info.upjao_overall_score ? `${row.stock_info.upjao_overall_score} %` : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Moisture')}</p>,
      width: '5rem',
      selector: row => row.stock_info.sample_moisture ? `${row.stock_info.sample_moisture}` : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='p-0 m-0 fw-bold'>{t('Closed Price')}</p>
        <p className='p-0 m-0 fw-bold'>({t('Per')} {t(conversion)})</p>
      </div>,
      width: '5.5rem',
      selector: row => <p className='display-inline'>&#8377;
        {Math.round(row.max_bid.bid_price * 100) / 100}
        {/* {row.auction_info.base_price_unit}  */}
      </p>,
      // sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Start Time')}</p>,
      width: '9.3rem',
      selector: row => row.auction_info.start_time ? IST_date_time(row.auction_info.start_time) : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Closed Time')}</p>,
      width: '9.3rem',
      selector: row => row.auction_info?.closed_at ? IST_date_time(row.auction_info.closed_at) : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Bid Status')}</p>,
      width: '5.4rem',
      selector: row => row.auction_info.is_closed && (row.auction_info.winning_bidder === '') ? <p className='color-red m-0 p-0'><i>{t('Exited')}</i></p>
        : row.auction_info.is_closed && (row.auction_info.winning_bidder !== '') ? <p className='color-green m-0 p-0'><i>{t('Sold-out')}</i></p> : '',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('pdf')}</p>,
      width: '3rem',
      selector: row => row.stock_info.upjao_scan_id ?
        <BsFileEarmarkPdfFill className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center' }} onClick={() => downloadPdf(row.stock_info.upjao_scan_id, row.stock_info.stock_crop_name)} />
        : '-',
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('View Bid')}</p>,
      width: '4.5rem',
      selector: row => <div><CButton color='primary' shape="rounded-pill" variant='outline' size='sm' className='m-tb-2' style={{ padding: '1px 5px', fontSize: 11 }}
        onClick={() => viewBid(row.auction_info.auction_id)}>{t('View Bid')}</CButton></div>,
      sortable: true,
    },
  ];

  const viewBid = (auctionId) => {
    setShowBid(!showBid)
    setAuctionId(auctionId)
  }

  const downloadPdf = async (scanId, crop_name) => {
    try {
      setIsShowDownloaded(true)
      const response = await getDownloadCropScanPdfAPI(scanId)
      if (response.status === 200) {
        downloadCropScanPdf(response.data, scanId, crop_name)
        setIsShowDownloaded(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {isShowDownloaded && <div className='pdf-downloading-toast'>
        <span><CSpinner size="sm" color='light' as="span" style={{ height: '1.3rem', width: '1.3rem', marginTop: 5, marginRight: 10 }} /></span><span>downloading...</span>
      </div>}
      {showBid
        ? <TableView
          tableHeading={t('History Details')}
          columns={columns}
          tableContent={filterData}
          dataLoading={loading}
          isDateFilter={true}
          setDateRange={setDateRange}
          dateRange={dateRange}
          filterKeys={['user_id', 'stock_info.stock_crop_name', 'auction_info.start_time', 'max_bid.bid_price', 'stock_info.stock_quantity', 'stock_info.upjao_overall_score']} />
        : <AuctionBidDetail changeBidView={setShowBid} auctionId={auctionId} />}

      <ReactTooltip
        id="tooltip"
        place="top"
        content={tooltip_content}
        style={{ backgroundColor: 'lightgray', color: 'black', border: '1px solid gray' }}
      />
    </div>
  )
}

export default History