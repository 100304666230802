import { CContainer, CFormInput } from "@coreui/react";
import { cilArrowLeft } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import DropDownWidget from "./DropDownWidget";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import DatePanel from "react-multi-date-picker/plugins/date_panel"

const TableHeadingWidget = ({ filterText, onFilter, onArrowAction, tableHeading, isBackArrow, placeholder,
    isDateFilter, setDateRange, cropList, tradersList, farmersList, dateRange }) => {
    const { t } = useTranslation();
    return (
        <CContainer fluid className="p-0 display-flex justify-between">
            <div>
                <h6 className="m-t-10 APP-COLOR fs-16">{isBackArrow && <CIcon className="cursor" onClick={onArrowAction} icon={cilArrowLeft} />} &nbsp; <b>{tableHeading}</b></h6>
            </div>

            <div className="display-flex justify-between">
                {cropList && <DropDownWidget
                    dropDownHeading={t('Crop')}
                    dropDownList={cropList}
                />}

                {tradersList && <DropDownWidget
                    dropDownHeading={t('Traders')}
                    dropDownList={tradersList}
                />}

                {farmersList && <DropDownWidget
                    dropDownHeading={t('Farmers')}
                    dropDownList={tradersList}
                />}

                {isDateFilter && <DatePicker
                    range
                    rangeHover
                    placeholder={t('Start Date') + '~' + t('End Date')}
                    shadow
                    numberOfMonths={1}
                    // animations={[
                    //     transition({ duration: 800, from: 35 })
                    // ]}

                    plugins={[
                        <Toolbar position="bottom" />,
                        <DatePanel />
                    ]}
                    value={dateRange}
                    onChange={setDateRange}
                    style={{ padding: '15px', height: '35px' }}
                />}

                <CFormInput
                    id="search"
                    type="text"
                    placeholder={placeholder}
                    aria-label="Search Input"
                    value={filterText}
                    onChange={onFilter}
                    className="w-20per"
                    style={{ height: '35px', minWidth: 200 }}
                />
            </div>
        </CContainer>
    )
};

export default TableHeadingWidget;