import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import hindi_path from '../../assets/NotoSerifDevanagari_SemiCondensed-Medium.ttf'
import gujarati_path from '../../assets/NotoSerifGujarati-Medium.ttf'

Font.register({
  family: 'my_font_family',
  fontWeight: 'normal',
  src: localStorage.getItem('language') === 'gu' ? gujarati_path : hindi_path
});

const styles = StyleSheet.create({
  displayLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 1,
    fontSize: 10,
  },
  displayRight: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 1,
    fontSize: 10,
  },
  key: {
    fontSize: 12
  },
  value: {
    color: 'rgb(49, 48, 48)'
  },
  fontFamily: {
    fontFamily: 'my_font_family'
  }
});

const ContentView = ({ company_information, styleText }) => {
  const { t } = useTranslation()
  const entries = Object.entries(company_information);
  const rows = entries.map(([key, value]) => (
    <View style={styleText === 'left' ? styles.displayLeft : styles.displayRight} key={key}>
      <Text style={[styles.key, styles.fontFamily]}>{key ? `${t(key)}: ` : ' '}</Text>
      <Text style={[styles.value, styles.fontFamily]}>{value ? ` ${value}` : ' '}</Text>
    </View>
  ));

  return <>{rows}</>;
};

export default ContentView;
