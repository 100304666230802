import React, { useState } from 'react'
import Dashboard from '../views/dashboard'
import ErrorView from '../views/ErrorView'
import Login from '../views/authentication/login'
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './protected_route';
import { ToastContainer } from 'react-toastify';

function AppRoute() {
    return (
        <div>
            <ToastContainer/>
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute>
                        <Login />
                    </ProtectedRoute>
                } />
                <Route path="/dashboard/*" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route path="/login" element={<Login />} />
                <Route path='/*' element={<ErrorView />} />
            </Routes>
        </div>
    )
}

export default AppRoute