import moment from 'moment-timezone';

export const IST_date_time = (date_time) => {
    const utcMoment = moment.utc(date_time, 'YYYY-MM-DD HH:mm:ss');
    const istMoment = utcMoment.tz('Asia/Kolkata');
    const istDateTimeFormatted = istMoment.format('DD/MM/YYYY, hh:mm A');
    return istDateTimeFormatted;
}




