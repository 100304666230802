import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CAvatar, CHeader, CHeaderToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu, cilUser, cilEnvelopeOpen, cilPhone, cilAccountLogout } from '@coreui/icons'
import '@coreui/coreui/dist/css/coreui.min.css'
import LanguageDropDown from '../widgets/LanguageDropdown'
import i18next from 'i18next'
import { auth } from '../firebase'
import { signOut } from 'firebase/auth'
import MessageModal from '../widgets/MessageModel'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom'
import { TbLogout } from 'react-icons/tb';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdLanguage } from "react-icons/md";


const AppHeader = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = useRef(null)
  const [logoutModel, setLogoutModel] = useState(false);

  const handleClickOutsideMenu = (event) => {
    if (closeMenu.current && !closeMenu.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
  }, []);

  const handleChangeLanguage = (e) => {
    i18next.changeLanguage(e.target.value)
    localStorage.setItem('language', e.target.value)
    window.location.reload()
  }

  const logout = async () => {
    await signOut(auth).then(() => {
      localStorage.clear();
      i18n.changeLanguage('en');
      navigate("/");
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <CHeader className='APP-BACKGROUND' position='sticky'>
      <div
        className='display-flex justify-content-between w-100per'>
        <div className='display-flex'>
          <CHeaderToggler onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
            <CIcon icon={cilMenu} className='color-white' size="lg" />
          </CHeaderToggler>
          <div className='color-white m-t-7 p-r-10'>
            <h6 className='m-0 text-align-left'>{localStorage.getItem('company_detail').split(",")[0] ? localStorage.getItem('company_detail').split(",")[0] : " "}</h6>
            <p className='m-0 fs-12 text-align-left'>{localStorage.getItem('company_detail').split(",")[1] ? localStorage.getItem('company_detail').split(",")[1] : " "}</p>
          </div>
        </div>


        <div className='display-flex justify-content-end pt-2'>
          <div>
            <h6 className='color-white m-t-7 p-r-10'>{localStorage.getItem('user_role') === "Trader" ? t('Trader') : t(localStorage.getItem('user_role'))}</h6>
          </div>
          <CAvatar className='cursor' color="light" textColor="white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            src={localStorage.getItem('photoURL')}>
            <CIcon className='color-gray' title='Profile Image' icon={cilUser} size="sm" />
          </CAvatar>
          {<MessageModal
            isVisible={logoutModel}
            title={t('Logout')}
            message={t('Do you want logout ?')}
            handleClose={() => setLogoutModel(!logoutModel)}
            handleTrigger={logout}
          />}
          {isMenuOpen && (
            <div className="menu" ref={closeMenu} style={{ borderRadius: '6px', padding: '10px 20px', minWidth: '250px' }}>
              <div className='display-flex justify-content-center align-item-center m-t-10 m-b-5'>
                <CAvatar className='cursor' color="light" textColor="white"
                  style={{ width: '50px', height: '50px' }}
                  src={localStorage.getItem('photoURL')}>
                  <CIcon className='color-gray' title='Profile Image' icon={cilUser} size="sm" />
                </CAvatar>
              </div>
              <div className='display-flex justify-content-center align-item-center'>
                <div>
                  <p className='m-0 p-0 text-align-center fs-18 fw-bold'>{localStorage.getItem('name')}</p>
                  <p className='m-0 p-0 text-align-center fs-14 display-flex flex-column m-l-10'>
                    <span className='m-0 p-0 color-gray'>
                      {localStorage.getItem('email') !== "null" ? <MdEmail className='APP-COLOR fs-12' /> : <FaPhoneAlt className='APP-COLOR fs-10' />} &nbsp;
                      {localStorage.getItem('email') !== "null" ? localStorage.getItem('email') : localStorage.getItem('phoneNumber')}</span>
                    <span className='m-0 p-0'> <span className='m-0 p-0 color-gray'>Access level -</span><span className="color-gray">{localStorage.getItem('user_role')}</span></span></p>
                </div>
              </div>
              <hr style={{ width: '100%', padding: 0 }} />
              <div className='menu-item color-black'>
                <MdLanguage className="color-black" style={{ marginTop: 5, fontSize: 20 }} color='gray' />
                <LanguageDropDown onchange={(e) => handleChangeLanguage(e)} />
              </div>
              <div className='menu-item cursor color-black fs-16' style={{ paddingRight: '20px', }} onClick={() => setLogoutModel(!logoutModel)}>
                <TbLogout style={{ marginTop: 5, fontSize: 20 }} />
                &nbsp;{t('Logout')}
              </div>
            </div>
          )}
        </div>
      </div>
    </CHeader>
  )
}

export default AppHeader
