import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react'
import { navItems } from './ContentPath'

const AppContent = () => {
    const user_role = localStorage.getItem('user_role')
    const onGetAccessDefaultPath = () => {
        let default_path
        switch (user_role) {
            case 'PACS-Admin':
                default_path = "/dashboard/farmers"
                break;
            case 'APMC-Admin':
                default_path = "/dashboard/traders"
                break;
            case 'Trader':
                default_path = "/dashboard/liveAuction"
                break
            default:
                default_path = ''
                break;
        }
        return default_path
    }

    return (
        <div>
            <Suspense fallback={<CSpinner color="primary" />}>
                <Routes>
                    {navItems.map((route, idx) => {
                        return (
                            route.element && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    element={< route.element />}
                                />
                            )
                        )
                    })}
                    <Route path="/" element={<Navigate to={onGetAccessDefaultPath()} replace />} />
                    <Route path="/*" element={<Navigate to="/error" replace />} />
                </Routes>
            </Suspense>
        </div>
    )
}

export default React.memo(AppContent)
