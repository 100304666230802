import React, { useEffect, useState } from 'react'
import TableView from './TableView';
import { getAllCompanyFarmers, getAuctionForInvoiceOrder, getCompanyAuctionList, getUserOneDetail } from '../utils/api';
import { CButton, CFormCheck } from '@coreui/react';
import { cilArrowRight } from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import InvoiceOrderDetail from './InvoiceOrderDetail';
import { useTranslation } from 'react-i18next';
import { IST_date_time } from '../utils/ist_date_time_conversion';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { onAttachedReverseGeoLocationAuction } from '../utils/common';
import { unitPriceConversion } from '../utils/unitPriceConversion';
import { unitQuantityConversion } from '../utils/unitQuantityConversion';

function Invoice() {
    const [loading, setLoading] = useState(true);
    const [winnerList, setWinnerList] = useState([]);
    const [showDetail, setShowDetail] = useState(true)
    const [oneWinnerData, setOneWinnerData] = useState('')
    const [dateRange, setDateRange] = useState()
    const [tooltip_content, setTooltipContent] = useState('')
    const [filterData, setFilterData] = useState([])
    const [conversion, setConversion] = useState('Mann')

    const { t } = useTranslation()
    useEffect(() => {
        (async () => {
            try {
                const response = await getAuctionForInvoiceOrder()
                if (response?.data?.data) {
                    const invoice_list = await Promise.all(response.data.data.map(async (item) => {
                        const weight_conversion = unitQuantityConversion("Mann", item.stock_info.stock_unit, item.stock_info.stock_quantity)
                        const price_conversion = unitPriceConversion("Mann", item.stock_info.stock_unit, item.stock_info.stock_price)
                        item.stock_info.stock_unit = weight_conversion.conversion;
                        item.stock_info.stock_quantity = weight_conversion.convertedQuantity;
                        // item.stock_price_unit = price_conversion.conversion;
                        item.stock_info.stock_price = price_conversion?.convertedPrice;
                        return item
                    }))
                    const final_data = await onAttachedReverseGeoLocationAuction(invoice_list, 'stock_info.stock_location')
                    setWinnerList(final_data)
                    setFilterData(final_data)
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(!loading)
        })()
    }, [])

    useEffect(() => { }, [showDetail])

    useEffect(() => {
        if (dateRange && dateRange.length === 2) {
            dataAccordingToDate(dateRange[0].format(), dateRange[1].format())
        } else {
            setFilterData(winnerList)
        }
    }, [dateRange])

    const dataAccordingToDate = (startDateString, endDateString) => {
        console.log(startDateString, endDateString);
        const startDate = new Date(startDateString.replace(/-/g, '/'));
        const endDate = new Date(endDateString.replace(/-/g, '/'));
        endDate.setDate(endDate.getDate() + 1);

        const filteredData = winnerList.filter(item => {
            const itemClosedDate = new Date(item.auction_info.closed_at.replace(/-/g, '/'));
            return itemClosedDate >= startDate && itemClosedDate <= endDate;
        });
        setFilterData(filteredData)
    }

    const onHandleUnitConversion = async (event) => {
        const value = event.target.value
        const array = await Promise.all(winnerList && winnerList?.map(async (item) => {
            const weight_conversion = unitQuantityConversion(value, item.stock_info.stock_unit, item.stock_info.stock_quantity)
            const price_conversion = unitPriceConversion(value, item.stock_info.stock_unit, item.stock_info.stock_price)
            item.stock_info.stock_unit = weight_conversion.conversion;
            item.stock_info.stock_quantity = weight_conversion.convertedQuantity;
            // item.stock_price_unit = price_conversion.conversion;
            item.stock_info.stock_price = price_conversion?.convertedPrice;
            return item
        }))
        setConversion(value);
        setFilterData(array)
    }

    const columns = [
        {
            name: <CFormCheck type="radio" disabled />,
            width: '2rem',
            selector: row => <CFormCheck
                checked={oneWinnerData === row}
                onChange={() => { setOneWinnerData(row) }}
                style={{ cursor: "pointer" }}
                type="radio"
            />
        },
        {
            name: <p className='m-0 p-0 fw-bold'>{t('Lot id')}</p>,
            width: '5rem',
            selector: row => row.stock_info.lot_id ? row.stock_info.lot_id : '-',
            sortable: true,
        },
        {
            name: <p className='m-0 p-0 fw-bold'>{t('Crop')}</p>,
            width: '5rem',
            selector: row => row.stock_info.stock_crop_name ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(`${row.stock_info.stock_crop_name} ${row.stock_info.stock_crop_variety !== "default" ? `(${row.stock_info.stock_crop_variety})` : ''}`)}>
                {row.stock_info.stock_crop_name} {row.stock_info.stock_crop_variety !== "default" ? `(${row.stock_info.stock_crop_variety})` : ''}</p> : '-',
            sortable: true,
        },
        {
            name: <p className='m-0 p-0 fw-bold'>{t('PACS')}</p>,
            width: '12rem',
            selector: row => row?.farmer_sub_company ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row.farmer_sub_company)}>{row.farmer_sub_company}</p> : '-',
            sortable: true,
        },
        {
            name: <p className='m-0 p-0 fw-bold'>{t('Farmers')}</p>,
            width: '9rem',
            selector: row => row?.winning_farmer ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row.winning_farmer)}>{row.winning_farmer}</p> : '-',
            sortable: true,
        },
        {
            name: t('APMC'),
            width: '12rem',
            selector: row => row?.trader_sub_company ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row?.trader_sub_company)}>{row?.trader_sub_company}</p> : '-',
            sortable: true,
        },
        {
            name: <div>
                <p className='p-0 m-0 fw-bold'>{t('Quantity')}</p>
                <select onChange={onHandleUnitConversion}
                    style={{ borderRadius: '10px', border: '1px solid green', padding: '0px 2px', margin: '3px 0px' }}
                >
                    <option value='Mann'>{t('Mann')}</option>
                    <option value='KG'>{t('KG')}</option>
                    <option value='Quintal'>{t('Quintal')}</option>
                    <option value='Tonne'>{t('Tonne')}</option>
                </select>
            </div>,
            width: '6rem',
            selector: row => row.stock_info.stock_quantity ? `${row.stock_info.stock_quantity}` : '-',
            // sortable: true,
        },
        {
            name: <div>
                <p className='p-0 m-0 fw-bold'>{t('Unit Price')}</p>
                <p className='p-0 m-0 fw-bold'>({t('Per')} {t(conversion)})</p>
            </div>,
            width: '6rem',
            selector: row => row.stock_info.stock_price ? <p className='display-inline'>&#8377; {row.stock_info.stock_price} </p> : '-',
            sortable: true,
        },
        {
            name: <p className='m-0 p-0 fw-bold'>{t('Address')}</p>,
            width: '7.5rem',
            selector: row => row?.stock_info?.stock_location ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row?.stock_info?.location_data?.display_name)}>{row?.stock_info?.location_data?.display_name}</p> : '-',
            sortable: true,
        },
        {
            name: <div>
                <p className='p-0 m-0'>{t('Quality')}</p>
                <p className='p-0 m-0'>{t('Score')}</p>
            </div>,
            width: '4.8rem',
            selector: row => row.stock_info.upjao_overall_score ? `${row.stock_info.upjao_overall_score} %` : '-',
            sortable: true,
        },
        {
            name: <p className='m-0 p-0 fw-bold'>{t('Sold')}</p>,
            width: '9rem',
            selector: row => row.auction_info.closed_at ? IST_date_time(row.auction_info.closed_at) : '-',
            sortable: true,
        },
    ];

    return (
        <div>
            {showDetail ? <TableView
                tableHeading={t('Invoice')}
                columns={columns}
                tableContent={filterData}
                dataLoading={loading}
                isDateFilter={true}
                setDateRange={setDateRange}
                dateRange={dateRange}
                filterKeys={[
                    'stock_info.lot_id',
                    'stock_info.stock_crop_name',
                    'farmer_sub_company',
                    'trader_sub_company',
                    'winning_farmer',
                    'stock_info.upjao_overall_score',
                    'stock_info.location_data.display_name',
                    'stock_info.stock_quantity']}
            /> : <InvoiceOrderDetail changeShowDetail={setShowDetail} winnerData={oneWinnerData} />}

            {showDetail && <div className='display-flex justify-content-end p-r-10'>
                {oneWinnerData && <CButton variant='outline' onClick={() => setShowDetail(!showDetail)}>{t('Show Details')} &nbsp;<CIcon className="cursor" icon={cilArrowRight} /></CButton>}
            </div>}

            <ReactTooltip
                id="tooltip"
                place="top"
                border={true}
                content={tooltip_content}
                style={{ backgroundColor: 'lightgray', color: 'black'}}
            />
        </div>
    )
}

export default Invoice