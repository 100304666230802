import React, { useRef, useState, useEffect } from 'react'
import { CButton, CFormInput, CSpinner } from '@coreui/react';
import { getQrNumberResponce } from '../utils/api';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { QRCodeCanvas } from 'qrcode.react';
import HistoryQrCode from './QrCodeHistory';

function GenerateQRcode() {
    const [noOfQR, setNoOfQR] = useState('');
    const [typeOfBag, setTypeOfBag] = useState('');
    const [listOfLotID, setListOfLotID] = useState([])
    const [captureDivArray, setCaptureDivArray] = useState([])
    const [numberOfPages, setNumberOfPages] = useState(0)
    const divRefs = useRef([]);
    const capturedImages = [];
    const pageItem = 6 // number of qrcode in a page
    const [showHistoryQrCode, setShowHistoryQrCode] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const response = typeOfBag && await getQrNumberResponce(Number(noOfQR))       
                if (typeOfBag === 'Sample bag') {
                    const updatedList = response?.data?.data.flatMap((element) => Array(3).fill(element));
                    updatedList && setListOfLotID(updatedList)
                    const page_count = Math.ceil(updatedList.length / pageItem)
                    setNumberOfPages(page_count)
                    divRefs.current = updatedList.map(() => React.createRef())
                } else {
                    response?.data?.data && setListOfLotID(response.data.data)
                    const page_count = Math.ceil(response?.data?.data.length / pageItem)
                    setNumberOfPages(page_count)
                    divRefs.current = response?.data?.data.map(() => React.createRef())
                }
                if (response?.status === 200 || !noOfQR) {
                    setLoading(false)
                }
                
            } catch (error) {
                console.error(error);
            }
        })()
    }, [typeOfBag, noOfQR])

    useEffect(() => {
        const captureImages = async () => {
            if (divRefs.current) {
                for (let i = 0; i < numberOfPages; i++) {
                    const divRef = divRefs.current[i];
                    const canvas = await html2canvas(divRef.current);
                    const imageURL = canvas.toDataURL();
                    capturedImages.push(imageURL);
                }
                // console.log(capturedImages);
                setCaptureDivArray(capturedImages)
            }
        };
        captureImages();
    }, [listOfLotID]);

    const downloadZip = (event) => {
        event.preventDefault();
        const zip = new JSZip();
        const folder = zip.folder('qrcodeList');

        captureDivArray.forEach((imageUrl, index) => {
            const imageData = imageUrl.split(',')[1];
            const filename = `image_${index + 1}.png`;
            folder.file(filename, imageData, { base64: true });
        });

        zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = 'qrcodeList.zip';
            link.click();
        });
    };

    const listOfQRcode = listOfLotID.map((id, index) => (
        <div key={index}>
            <QRCodeCanvas
                value={id}
                size={220}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"H"}
                includeMargin={false}
                imageSettings={{
                    // src: "https://static.zpao.com/favicon.png",
                    x: undefined,
                    y: undefined,
                    height: 24,
                    width: 24,
                    excavate: true,
                }}
            />
            <div id={index} className='text-align-left' style={{ height: '60px' }}>
                <p className='m-0 text-align-center'>{id}/{typeOfBag}</p>
                <p className='m-0'>LOT ID: ______________</p>
                <p className='m-0'>Farmer: _____________</p>
                <p className='m-0'>PACS: ________________</p>
                <p className='m-0'>APMC: {localStorage.getItem('company_detail').split(",")[1]}</p>
            </div>
        </div>
    ));

    const showQRimageInAPage = (index) => {
        const startIndex = index * pageItem; // pageItem = 6
        const endIndex = startIndex + pageItem;
        const batchElements = listOfQRcode.slice(startIndex, endIndex);
        return (
            <div className="grid-container">
                {batchElements.map((element, index) => (
                    <div key={index} className="A4-item6">
                        {element}
                    </div>
                ))}
            </div>
        )
    }

    const renderElements = () => {
        const elements = [];
        if (listOfLotID && numberOfPages) {
            for (let index = 0; index < numberOfPages; index++) {
                elements.push(
                    <div
                        key={index}
                        className='A4-sheet'
                        ref={divRefs && divRefs.current[index]}
                        style={{
                            position: 'absolute',
                            top: 150,
                        }}
                    >
                        {showQRimageInAPage(index)}
                    </div>
                );
            }
        }
        return elements;
    };

    const onHandleNumberOfQR = (e) => {
        setNoOfQR(e.target.value)
        setLoading(true)
    }

    return (
        <div>
            {showHistoryQrCode ?
                <div>
                    <header className='p-1'>
                        <form onSubmit={downloadZip}>
                            <div className='display-flex justify-content-between'>
                                <CFormInput
                                    style={{ display: 'inline' }}
                                    className='w-25'
                                    placeholder="Number Of QR Code"
                                    type="number"
                                    id=''
                                    autoFocus
                                    min={1}
                                    value={noOfQR ? noOfQR : ''}
                                    onChange={(e) => onHandleNumberOfQR(e)}
                                    required
                                />
                                <select
                                    className='w-25'
                                    style={{ border: '1px solid lightgray', borderRadius: '7px' }}
                                    value={typeOfBag}
                                    disabled={!noOfQR}
                                    onChange={(e) => setTypeOfBag(e.target.value)}
                                    required
                                >
                                    <option value=''>Select Bag Type</option>
                                    <option value='Sample bag'>Sample Bag</option>
                                    <option value='Large bori'>Large Bori</option>
                                </select>

                                <CButton color='primary' shape="rounded-pill" variant='outline' size='sm' className='m-tb-2' style={{ padding: '0px 5px' }} type='submit' disabled={loading}> Generate QR Code</CButton>
                                <CButton color='warning' shape="rounded-pill" variant='outline' size='sm' className='m-tb-2' style={{ padding: '0px 5px' }} onClick={() => setShowHistoryQrCode(!showHistoryQrCode)}> QR Code History</CButton>
                            </div>
                        </form>
                    </header>
                    <br></br>

                    <main className='display-flex justify-content-center align-item-center'>
                        {loading
                            ? <CSpinner color='primary' />
                            : noOfQR && renderElements()
                        }
                    </main>
                </div>
                : <HistoryQrCode viewQrCodeHistory={setShowHistoryQrCode} />}
        </div>
    )
}

export default GenerateQRcode