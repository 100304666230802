import React, { useEffect, useState } from 'react'
import TableView from './TableView';
import { getAllParentCompanyUsers } from '../utils/api';
import { useTranslation, withTranslation } from 'react-i18next'

function Traders() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [trader, setTraders] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllParentCompanyUsers()
        // console.log("traders", response);
        const traderList = response?.data?.data && response?.data?.data?.filter((element) => (
          element.user_details.user_role === 'Trader'
        ))
        traderList && setTraders(traderList)
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(!loading)
      }
    })()
  }, [])



  const columns = [
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Trader Name')}</p>,
      selector: row => row?.user_details?.first_name ? `${row?.user_details?.first_name} ${row?.user_details?.last_name}` : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Email ID')} / {t('Phone Number')}</p>,
      selector: row => row?.user_details?.phone_number ? row?.user_details?.phone_number : row?.user_details?.email,
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Sub Company Name')}</p>,
      selector: row => row?.sub_company_details.company_name ? row?.sub_company_details.company_name : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('User Id')}</p>,
      selector: row => row?.user_details?.short_user_id ? row?.user_details?.short_user_id : '-',
      sortable: true,
    },
  ];

  return (
    <div>
      <TableView
        tableHeading={t('Traders List')}
        columns={columns}
        tableContent={trader}
        dataLoading={loading}
        filterKeys={['user_details.first_name', 'user_details.last_name', 'user_details.phone_number', 'user_details.email', 'user_details.short_user_id', 'sub_company_details.company_name']}
      />
    </div>
  )
}

export default withTranslation()(Traders)