import React, { useEffect, useState } from 'react'
import TableView from './TableView'
import { getDownloadCropScanPdfAPI, getStockList } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkPdfFill } from 'react-icons/bs'
import { downloadCropScanPdf } from '../utils/downloadPDF';
import { IST_date_time } from '../utils/ist_date_time_conversion';
import { CSpinner } from '@coreui/react';
import { onAttachedReverseGeoLocation } from '../utils/common';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { unitQuantityConversion } from '../utils/unitQuantityConversion'
import { unitPriceConversion } from '../utils/unitPriceConversion'

function StocksList() {
  const MANN = 20;
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [stocks, setStocks] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState([])
  const [conversion, setConversion] = useState('Mann')
  const [isShowDownloaded, setIsShowDownloaded] = useState(false)
  const [tooltip_content, setTooltipContent] = useState('')

  useEffect(() => {
    (async () => {
      if (navigator.geolocation) {
        const response = await getStockList();
        if (response?.data?.data) {
          const updatedList = response?.data?.data
          const array = await Promise.all(updatedList && updatedList?.map(async (item) => {
            const weight_conversion = unitQuantityConversion("Mann", item.stock_unit, item.stock_quantity)
            const price_conversion = unitPriceConversion("Mann", item.stock_unit, item.stock_price)
            item.stock_unit = weight_conversion.conversion;
            item.stock_quantity = weight_conversion.convertedQuantity;
            item.stock_price_unit = price_conversion.conversion;
            item.stock_price = price_conversion?.convertedPrice;
            return item
          }))
          if (response?.data?.data) {
            const final_list = await onAttachedReverseGeoLocation(array, 'stock_location')
            setStocks(final_list)
            setFilteredStocks(final_list)
          }
        }
        setLoading(false)
      }
    })()
  }, [])

  const onHandleUnitConversion = async (event) => {
    const value = event.target.value
    const array = await Promise.all(stocks && stocks?.map(async (item) => {
      const weight_conversion = unitQuantityConversion(value, item.stock_unit, item.stock_quantity)
      const price_conversion = unitPriceConversion(value, item.stock_unit, item.stock_price)
      item.stock_unit = weight_conversion.conversion;
      item.stock_quantity = weight_conversion.convertedQuantity;
      item.stock_price_unit = price_conversion.conversion;
      item.stock_price = price_conversion?.convertedPrice;
      return item
    }))
    setFilteredStocks(array)
    setConversion(value)
  }

  const columns = [
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Lot id')}</p>,
      width: '6rem',
      selector: row => row.lot_id ? row.lot_id : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Crop')}</p>,
      width: '8.5rem',
      selector: row => row.stock_crop_name ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(`${row.stock_crop_name} ${row.stock_crop_variety !== "default" ? `(${row.stock_crop_variety})` : ''}`)}>
        {row.stock_crop_name} {row.stock_crop_variety !== "default" ? `(${row.stock_crop_variety})` : ''}</p> : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='m-0 p-0 fw-bold'>{t('Quantity')}</p>
        <select onChange={onHandleUnitConversion}
          style={{ borderRadius: '10px', border: '1px solid green', padding: '0px 0px', margin: "3px 0px" }}
        >
          <option value='KG' selected={conversion === 'KG'}>{t('KG')}</option>
          <option value='Mann' selected={conversion === 'Mann'}>{t('Mann')}</option>
          <option value='Quintal' selected={conversion === 'Quintal'}>{t('Quintal')}</option>
          <option value='Tonne' selected={conversion === 'Tonne'}>{t('Tonne')}</option>
        </select>
      </div>,
      width: '8rem',
      selector: row => row.stock_quantity ? `${(row.stock_quantity).toFixed(2)}` : '-',
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Moisture')}</p>,
      width: '5rem',
      selector: row => row.stock_quantity.sample_moisture ? `${row.stock_quantity.sample_moisture}` : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='m-0 p-0 fw-bold'>{t('Base Price')}</p>
        <p className='m-0 p-0 fw-bold'>({t('Per')} {t(conversion)})</p>
      </div>,
      width: '5.9rem',
      selector: row => row.stock_price ? <p className='display-inline'>&#8377; {row.stock_price} </p> : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Quality Scan Id')}</p>,
      width: '8rem',
      selector: row => row.upjao_scan_id ? row.upjao_scan_id : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Quality Score')}</p>,
      width: '7rem',
      selector: row => row.upjao_overall_score ? `${row.upjao_overall_score} %` : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Bid Status')}</p>,
      width: '6rem',
      selector: row => row.stock_active ? <p className='color-green m-0 p-0'><i>{t('Active')}</i></p> : <p className='color-red m-0 p-0'><i>{t('InActive')}</i></p>,
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Location')}</p>,
      width: '10rem',
      selector: row => row?.stock_location ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row?.location_data?.display_name)}>{row?.location_data?.display_name}</p> : '-',
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Created At')}</p>,
      width: '10rem',
      selector: row => row.created_at ? IST_date_time(row.created_at) : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('pdf')}</p>,
      width: '3rem',
      selector: row => row.upjao_scan_id ?
        <BsFileEarmarkPdfFill className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center' }} onClick={() => downloadPdf(row.upjao_scan_id, row.stock_crop_name)} />
        : '-',
    },
  ];

  const downloadPdf = async (scanId, crop_name) => {
    try {
      setIsShowDownloaded(true)
      const response = await getDownloadCropScanPdfAPI(scanId)
      if (response.status === 200) {
        downloadCropScanPdf(response.data, scanId, crop_name)
        setIsShowDownloaded(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {isShowDownloaded && <div className='pdf-downloading-toast'>
        <span><CSpinner size="sm" color='light' as="span" style={{ height: '1.3rem', width: '1.3rem', marginTop: 5, marginRight: 10 }} /></span><span>downloading...</span>
      </div>}
      <TableView
        tableHeading={t('Stock List')}
        columns={columns}
        tableContent={filteredStocks}
        dataLoading={loading}
        filterKeys={['stock_crop_name', 'stock_crop_variety', 'lot_id', 'stock_price', 'stock_quantity']}
      />
      <ReactTooltip
        id="tooltip"
        place="top"
        content={tooltip_content}
        style={{ backgroundColor: 'lightgray', color: 'black', border: '1px solid gray' }}
      />
    </div>
  )
}

export default StocksList