import React, { useState, useEffect, useRef } from 'react';
import TableView from './TableView';
import { getCompanyAuctionList, getDownloadCropScanPdfAPI } from '../utils/api';
import { CButton, CSpinner } from '@coreui/react';
import AuctionBidDetail from './AuctionBidDetail';
import { useInterval } from '../hooks/setInterval';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkPdfFill } from 'react-icons/bs'
import { downloadCropScanPdf } from '../utils/downloadPDF';
import { IST_date_time } from '../utils/ist_date_time_conversion';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { onAttachedReverseGeoLocationAuction } from '../utils/common';
import { unitQuantityConversion } from '../utils/unitQuantityConversion';
import { unitPriceConversion } from '../utils/unitPriceConversion';

function LiveAuction() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [companyAuction, setCompanyAuction] = useState([]);
  const [showBid, setShowBid] = useState(true)
  const [auctionId, setAuctionId] = useState('')
  const [conversion, setConversion] = useState('Mann')
  const MANN = 20;
  const [isShowDownloaded, setIsShowDownloaded] = useState(false)
  const [apiResponseStatus, setApiResponseStatus] = useState(null);
  const isTabVisibleRef = useRef(true)
  const [tooltip_content, setTooltipContent] = useState('')

  useEffect(() => {
    (async () => {
      try {
        const response = await getCompanyAuctionList(false)
        onSetInitialConversionOnData(response)
        setApiResponseStatus(response?.status)
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
      // onCallAuctionAPI()
    })()
  }, [])

  useEffect(() => { }, [showBid])

  useInterval(() => {
    if (isTabVisibleRef.current && companyAuction) {
      onCallAuctionAPI();
    }
  }, 5000);

  useEffect(() => {
    const handleVisibilityChange = () => {
      isTabVisibleRef.current = !document.hidden;
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const onCallAuctionAPI = async () => {
    try {
      if (apiResponseStatus === 200) {
        const response = await getCompanyAuctionList(false)
        onSetInitialConversionOnData(response)
      }
      else {
        setCompanyAuction(companyAuction);
        // console.log("set prev here");
      }
      setConversion('Mann')
    } catch (error) {
      console.error('Error fetching API:', error);
    }
  };

  const onSetInitialConversionOnData = async (response) => {
    if (response?.data?.data) {
      const auctionList = await Promise.all(response?.data?.data.map(async (item) => {
        const weight_conversion = unitQuantityConversion("Mann", item.stock_info.stock_unit, item.stock_info.stock_quantity)
        const price_conversion = unitPriceConversion("Mann", item.stock_info.stock_unit, item.max_bid.bid_price)
        item.stock_info.stock_unit = weight_conversion.conversion;
        item.stock_info.stock_quantity = weight_conversion.convertedQuantity;
        // item.stock_price_unit = price_conversion.conversion;
        item.max_bid.bid_price = price_conversion?.convertedPrice;
        return item
      }))
      const final_list = await onAttachedReverseGeoLocationAuction(auctionList, 'stock_info.stock_location')
      setCompanyAuction(final_list);
      setConversion('Mann');
      setLoading(false)
    } else {
      setCompanyAuction([])
    }
  }

  const onHandleUnitConversion = async (event) => {
    const value = event.target.value
    const array = await Promise.all(companyAuction.map(async (item) => {
      const weight_conversion = unitQuantityConversion(value, item.stock_info.stock_unit, item.stock_info.stock_quantity)
      const price_conversion = unitPriceConversion(value, item.stock_info.stock_unit, item.max_bid.bid_price)
      item.stock_info.stock_unit = weight_conversion.conversion;
      item.stock_info.stock_quantity = weight_conversion.convertedQuantity;
      // item.stock_price_unit = price_conversion.conversion;
      item.max_bid.bid_price = price_conversion?.convertedPrice;
      return item
    }))
    setConversion(event.target.value);
    setCompanyAuction(array);
  }

  const columns = [
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Lot id')}</p>,
      width: '5.5rem',
      selector: row => row.stock_info.lot_id ? row.stock_info.lot_id : '-',
      sortable: true
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Farmer ID')}</p>,
      width: '6rem',
      selector: row => row.user_id ? row.user_id : '-',
      sortable: true
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Crop')}</p>,
      width: '10rem',
      selector: row => row.stock_info.stock_crop_name ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(`${row.stock_info.stock_crop_name} ${row.stock_info.stock_crop_variety !== "default" ? `(${row.stock_info.stock_crop_variety})` : ''}`)}>
        {row.stock_info.stock_crop_name} {row.stock_info.stock_crop_variety !== "default" ? `(${row.stock_info.stock_crop_variety})` : ''}</p> : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='p-0 m-0 fw-bold'>{t('Quantity')}</p>
        <select onChange={onHandleUnitConversion}
          style={{ borderRadius: '10px', border: '1px solid green', padding: '0px 2px', margin: '3px 0px' }}
        >
          <option value='Mann'>{t('Mann')}</option>
          <option value='KG'>{t('KG')}</option>
          <option value='Quintal'>{t('Quintal')}</option>
          <option value='Tonne'>{t('Tonne')}</option>
        </select>
      </div>,
      width: '6rem',
      selector: row => row.stock_info.stock_quantity ? (row.stock_info.stock_quantity).toFixed(2) : '-',
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Address')}</p>,
      width: '10.5rem',
      selector: row => row?.stock_info?.stock_location ? <p data-tooltip-id="tooltip" className='cursor m-0' onMouseEnter={() => setTooltipContent(row?.stock_info?.location_data?.display_name)}>{row?.stock_info?.location_data?.display_name}</p> : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='p-0 m-0 fw-bold'>{t('Quality')}</p>
        <p className='p-0 m-0 fw-bold'>{t('Score')}</p>
      </div>,
      width: '5rem',
      selector: row => row.stock_info.upjao_overall_score ? `${row.stock_info.upjao_overall_score} %` : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Moisture')}</p>,
      width: '5rem',
      selector: row => row.stock_info.sample_moisture ? `${row.stock_info.sample_moisture}` : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Start Time')}</p>,
      width: '9rem',
      selector: row => row.auction_info.start_time ? IST_date_time(row.auction_info.start_time) : '-',
      sortable: true,
    },
    {
      name: <div>
        <p className='p-0 m-0 fw-bold'>{t('Highest Price')}</p>
        <p className='p-0 m-0 fw-bold'>({t('Per')} {t(conversion)})</p>
      </div>,
      width: '5.5rem',
      selector: row => <p className='display-inline'>&#8377; {Math.round(row.max_bid.bid_price)} </p>,
      // sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Bid Status')}</p>,
      width: '6rem',
      selector: row => row.auction_info.is_closed ? t('Sold') : <p className='App-color m-0 p-0'><i>{t('Active')}</i></p>,
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('pdf')}</p>,
      width: '3.5rem',
      selector: row => row.stock_info.upjao_scan_id ?
        <BsFileEarmarkPdfFill className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center' }} onClick={() => downloadPdf(row.stock_info.upjao_scan_id, row.stock_info.stock_crop_name)} />
        : '-',
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('View Bid')}</p>,
      width: '5rem',
      selector: row => <div><CButton color='primary' shape="rounded-pill" variant='outline' size='sm' className='m-tb-2' style={{ padding: '1px 5px', fontSize: 11 }}
        onClick={() => viewBid(row.auction_info.auction_id)}>{t('View Bid')}</CButton></div>,
      sortable: true,
    },
  ];

  const downloadPdf = async (scanId, crop_name) => {
    try {
      setIsShowDownloaded(true)
      const response = await getDownloadCropScanPdfAPI(scanId)
      if (response.status === 200) {
        downloadCropScanPdf(response.data, scanId, crop_name)
        setIsShowDownloaded(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const viewBid = (auctionId) => {
    setShowBid(!showBid)
    setAuctionId(auctionId)
  }

  return (
    <div>
      {isShowDownloaded && <div className='pdf-downloading-toast'>
        <span><CSpinner size="sm" color='light' as="span" style={{ height: '1.3rem', width: '1.3rem', marginTop: 5, marginRight: 10 }} /></span><span>downloading...</span>
      </div>}
      {showBid
        ? <TableView
          tableHeading={t('Auction Details')}
          no_data_found={!companyAuction.length ? t('Auction not found') : null}
          columns={columns}
          tableContent={companyAuction}
          dataLoading={loading}
          filterKeys={['user_id', 'stock_info.stock_crop_name', 'auction_info.start_time', 'max_bid.bid_price', 'stock_info.stock_quantity', 'stock_info.upjao_overall_score']} />
        : <AuctionBidDetail changeBidView={setShowBid} auctionId={auctionId} />}

      <ReactTooltip
        id="tooltip"
        place="top"
        content={tooltip_content}
        style={{ backgroundColor: 'lightgray', color: 'black', border: '1px solid gray' }}
      />
    </div>
  )
}

export default LiveAuction