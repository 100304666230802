const MANN = 20;
export const unitPriceConversion = (conversion, unit, base_price) => {
    switch (conversion) {
        case 'Mann':
            switch (unit) {
                case 'KG':
                    base_price = base_price * MANN;
                    break;
                case 'Quintal':
                    base_price = base_price * MANN / 100;
                    break;
                case 'Tonne':
                    base_price = base_price / 1000 * MANN;
                    break;
                default:
                    // console.error('1.Error mann');
                    break;
            }
            break;
        case 'KG':
            switch (unit) {
                case 'Mann':
                    base_price = base_price / MANN;
                    break;
                case 'Quintal':
                    base_price = base_price / 100;
                    break;
                case 'Tonne':
                    base_price = base_price / 1000;
                    break;
                default:
                    // console.error('2.Error kg');
                    break;
            }
            break;
        case 'Quintal':
            switch (unit) {
                case 'Mann':
                    base_price = base_price / MANN * 100;
                    break;
                case 'KG':
                    base_price = base_price * 100;
                    break;
                case 'Tonne':
                    base_price = base_price / 10;
                    break;
                default:
                    // console.error('3.Error quintal');
                    break;
            }
            break;
        case 'Tonne':
            switch (unit) {
                case 'Mann':
                    base_price = base_price / MANN * 1000;
                    break;
                case 'KG':
                    base_price = base_price * 1000;
                    break;
                case 'Quintal':
                    base_price = base_price * 10;
                    break;
                default:
                    // console.error('4.Error tonne');
                    break;
            }
            break;
        default:
            console.error('Unsupported price conversion');
            break;
    }
    return {
        conversion: conversion,
        convertedPrice: base_price
    }
}