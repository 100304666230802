import { CNavItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
    cilDollar,
    cilMic,
    cilHistory,
    cilWalk,
    cilQrCode,
    cilBook
} from '@coreui/icons'
import { GiGrain } from "react-icons/gi";
import { Trans, useTranslation } from 'react-i18next'

const Trader_sidebar = () => {
    const { t } = useTranslation();
    const Trader_NavList = [
        {
            component: CNavItem,
            name: <Trans>{'Live Auction'}</Trans>,
            to: '/dashboard/liveAuction',
            icon: <CIcon color='red' icon={cilMic} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        },
        {
            component: CNavItem,
            name: t('History'),
            to: '/dashboard/history',
            icon: <CIcon icon={cilHistory} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        },
        {
            component: CNavItem, 
            name: t('Purchase Order'),
            to: '/dashboard/purchaseOrder',
            icon: <CIcon icon={cilBook} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        }
    ]
    return Trader_NavList;
};

const APMC_sidebar = () => {
    const { t } = useTranslation();
    const APMC_NavList = [
        {
            component: CNavItem,
            name: <Trans>{'Traders'}</Trans>, 
            to: '/dashboard/traders',
            icon: <CIcon icon={cilWalk} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
            badge: { color: 'info', text: '' },
        },
        {
            component: CNavItem,
            name: <Trans>{'Live Auction'}</Trans>,
            to: '/dashboard/liveAuction',
            icon: <CIcon color='red' icon={cilMic} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        },
        {
            component: CNavItem,
            name: t('History'),
            to: '/dashboard/history',
            icon: <CIcon icon={cilHistory} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        },
        {
            component: CNavItem, 
            name: t('Purchase Order'),
            to: '/dashboard/purchaseOrder',
            icon: <CIcon icon={cilBook} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        },
        {
            component: CNavItem, 
            name: t('Generate QR Code'),
            to: '/dashboard/generateQrCode',
            icon: <CIcon icon={cilQrCode} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        },
    ]
    return APMC_NavList;
};

const PACS_sidebar = () => {
    const { t } = useTranslation();
    const PACS_NavList = [{
        component: CNavItem,
        name: t('Farmers'),
        to: '/dashboard/farmers',
        icon: <CIcon icon={cilWalk} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
        badge: { color: 'info', text: '' },
    },
    {
        component: CNavItem,
        name: t('Stocks'),
        to: '/dashboard/stocks',
        icon: <GiGrain/>,
    },
    {
        component: CNavItem,
        name: <Trans>{'Live Auction'}</Trans>,
        to: '/dashboard/liveAuction',
        icon: <CIcon icon={cilMic} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
    },
    {
        component: CNavItem,
        name: t('History'),
        to: '/dashboard/history',
        icon: <CIcon icon={cilHistory} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
    },
    {
        component: CNavItem,
        name: t('Invoice'),
        to: '/dashboard/invoice',
        icon: <CIcon icon={cilBook} customClassName="nav-icon" style={{'--ci-primary-color': 'rgb(34, 93, 153)'}}/>,
    },];
    return PACS_NavList;
};

export { APMC_sidebar, PACS_sidebar, Trader_sidebar };
