import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter } from "@coreui/react";
import { useTranslation } from "react-i18next";

const MessageModal = ({ isVisible, title, message, handleClose, handleTrigger }) => {
    const { t } = useTranslation()
    return (
        <CModal visible={isVisible} onClick={() => handleClose()}>
            <CModalHeader >
                <CModalTitle>{title}</CModalTitle>
            </CModalHeader>
            <CModalBody>{message}</CModalBody>
            <CModalFooter>
                <CButton variant="outline" color="secondary" onClick={() => handleClose()}>{t('NO')}</CButton>
                <CButton variant="outline" color="success" onClick={() => handleTrigger()}>{t('YES')}</CButton>
            </CModalFooter>
        </CModal>
    );
}

export default MessageModal;
