import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 8,
    },
    line: {
        border: '1px solid black',
        borderRadius: '1px',
        margin: '5px 50px'
    }
});

const PageFooter = () => (
    <View fixed style={styles.footer}>
        <hr style={styles.line} />
        <View>
            <Text>This is a computer generated record. Powered By: Upjao Agrotech LLP</Text>
            {/* <Text>Upjao Agrotech LLP. 603, Abhijeet IV, Near Law Garden, Ellis Bridge, Ahmedabad - 380006</Text>
            <Text>GSTIN: 24AAGFU8750R1ZC</Text>
            <Text>Please note that this invoice is not a demand for payment.</Text> */}
        </View>
    </View>
);

export default PageFooter