import React, { useEffect, useState } from 'react'
import TableView from './TableView';

function ReverseLiveAuction() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() =>{
    (async() => {
      setLoading(!loading)
      await fetch('https://jsonplaceholder.typicode.com/todos')
      .then(response => response.json())
      .then(json =>{ 
        setData(json)
      })
      setLoading(!loading)
  })()
  }, [])

  const columns = [
    {
      name: 'User ID',
      selector: row => row.id ? row.id : '-',
      sortable: true,
    },
    {
      name: 'User Name',
      selector: row => row.title ? row.title : '-',
      sortable: true,
    },
    {
      name: 'Id',
      selector: row => row.userId ? row.userId : '-',
      sortable: true,
    },
    {
      name: 'Completed',
      selector: row => row.completed ? row.completed : '-',
      sortable: true,
    },
  ];


  return (
    <div>
        <TableView 
          tableHeading={'List Of Farmers'}
          columns={columns}
          tableContent={data}
          dataLoading={loading}
          filterKeys={['id', 'title', 'userId']}
        />
    </div>
  )
}

export default ReverseLiveAuction