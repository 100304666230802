import axios from "axios";

const location_map = new Map();

export const scanLocation = async (latitude, longitude) => {
    try {
        const key = `${latitude},${longitude}`;
        if (location_map.has(key)) {
            return location_map.get(key);
        } else {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=0`);
            const data = response.data;
            location_map.set(key, data);
            return data;
        }
    } catch (error) {
        console.error("Error in reverse geo search:", error);
        return '-';
    }
}

export const onAttachedReverseGeoLocation = async (data, location_key) => {
    for(const row of data) {
        const location = row[location_key];
        const location_data = location ? await scanLocation(location.latitude, location.longitude) : '-';
        row['location_data'] = location_data;
    }
    location_map.clear()
    return data;
}

export const onAttachedReverseGeoLocationAuction = async (data, location_key) => {
    const location_cordinates = location_key.split(".");
    for(const row of data) {
        const location = row[location_cordinates[0]][location_cordinates[1]];
        const location_data = location ? await scanLocation(location.latitude, location.longitude) : '-';
        row[location_cordinates[0]]['location_data'] = location_data;
    }

    location_map.clear()
    return data;
}
