import { useTranslation } from "react-i18next"
const LanguageDropDown = (props) => {
    const { t } = useTranslation()
    const language = localStorage.getItem('language')
    return (
        <select className="cursor" value={language} onChange={props.onchange} style={{ border: 'none', width: '100%' }}>
            <option value={'en'}>{t('English')}</option>
            {/* <option value={'hi'}>{t('Hindi')}</option> */}
            <option value={'gu'}>{t('Gujarati')}</option>
        </select>
    )
}
export default LanguageDropDown