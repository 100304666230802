import React, { useEffect, useState } from 'react'
import TableView from './TableView';
import { getAllParentCompanyUsers, getAllCompanyFarmers } from '../utils/api';
import { useTranslation } from 'react-i18next';

function FarmerList() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [farmers, setFramers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        // const response = await getAllParentCompanyUsers()
        const response = await getAllCompanyFarmers()
        // console.log("farmer", response);
        const farmerList = response?.data?.data && response?.data?.data.filter((element) => (
          element.user_role === 'Farmer'
        ))
        farmerList && setFramers(farmerList)
      } catch (error) {
        console.error(error);
      }
      setLoading(!loading)
    })()
  }, [])

  const columns = [
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Farmer Name')}</p>,
      selector: row => row.first_name ? `${row.first_name} ${row.last_name}` : '-',
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('Email ID')} / {t('Phone Number')}</p>,
      selector: row => row.phone_number ? row.phone_number : row.email,
      sortable: true,
    },
    {
      name: <p className='m-0 p-0 fw-bold'>{t('User Id')}</p>,
      selector: row => row.short_user_id ? row.short_user_id : '-',
      sortable: true,
    },
  ];

  return (
    <div>
      <TableView
        tableHeading={t('Farmer List')}
        columns={columns}
        tableContent={farmers}
        dataLoading={loading}
        filterKeys={['first_name', 'last_name', 'phone_number', 'email', 'shortUserId']}
      />
    </div>
  )
}

export default FarmerList