import React, { useState } from 'react'
import { auth, provider } from '../../firebase'
import { signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import { CButton, CImage, CContainer, CInputGroup, CInputGroupText, CFormInput, CCard, CCardImage, CCardBody, CCardTitle, CSpinner, CAlert, } from '@coreui/react';
import upjao_logo from '../../assets/upjao.png'
import { RiLockPasswordFill } from "react-icons/ri";
import { GiPhone } from "react-icons/gi";

function Login() {
    const [loading, setLoding] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [OTP, setOTP] = useState();
    const [expandForm, setExpandForm] = useState(false);
    const navigate = useNavigate();
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
    const [isValidOTP, setIsValidOTP] = useState(true);
    const [errorMessage, setErrorMessage] = useState('')
    const [isGoogleButtonDisable, setIsGoogleButtonDisable] = useState(false)
    const [isPhoneButtonDisable, setIsPhoneButtonDisable] = useState(false)

    const getuserRole = async (token) => {
        localStorage.setItem('token', token)
        if (token) {
            try {
                const [response, roleResponce] = await Promise.all([
                    fetch(`${window.config.commonEndpoint}/users/get`, {
                        method: "GET", // *GET, POST, PUT, DELETE, etc.
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${token}`
                        },
                    }),
                    fetch(`${window.config.endpoint}/user`, {
                        method: "GET", // *GET, POST, PUT, DELETE, etc.
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                    })
                ])

                const userDetail = await response.json()
                const userRole = await roleResponce.json()

                // console.log("responce", userDetail);
                // console.log("roleResponce", userRole, userRole.status);
                if (userDetail.status) {
                    localStorage.setItem("email", userDetail.data.userInfo.email)
                    localStorage.setItem("phoneNumber", userDetail.data.userInfo.phone_number)
                    localStorage.setItem('name', userDetail.data.userInfo.first_name + ' ' + userDetail.data.userInfo.last_name)
                    localStorage.setItem('userID', userDetail.data.userInfo.firebase_user_id)
                }
                // TODO: Need modification here
                if (userRole.status && (userRole?.data?.user_role === "PACS-Admin" || userRole?.data?.user_role === "APMC-Admin" || userRole?.data?.user_role === "Trader")) {
                    localStorage.setItem('user_role', userRole?.data?.user_role);
                    localStorage.setItem('company_ids', [userDetail?.data?.companyInfo.id, userRole.data.sub_company_details.sub_company_id])
                    localStorage.setItem('company_detail', [userDetail?.data?.companyInfo.name, userRole.data.sub_company_details.company_name])
                    navigate('/dashboard')
                } else {
                    setErrorMessage('Unauthorized User')
                }
            } catch (error) {
                console.error(error);
                setErrorMessage('Something went wrong. Try Again Later...')
            } finally {
                setExpandForm(false)
                setGoogleLoading(false)
                setLoding(false)
            }
        }
    }

    // Sign in with Google
    const signInwithGoogle = async () => {
        setErrorMessage('')
        setGoogleLoading(true);
        setIsPhoneButtonDisable(true)
        await signInWithPopup(auth, provider).then(async (data) => {
            localStorage.setItem("email", data.user.email)
            localStorage.setItem('name', data.user.displayName)
            localStorage.setItem("photoURL", data.user.photoURL)
            // call api
            // getting user firebase token
            const token = await auth.currentUser.getIdToken(true)
            // console.log('token', token);
            token && getuserRole(token);
        }).catch((err) => {
            console.log(err);
            setGoogleLoading(false)
            setIsPhoneButtonDisable(false)
        })

    }

    // Sign in with mobile number
    const generateRecaptchaVerifier = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => { }
        }, auth);
    }

    const onSignInSubmit = (event) => {
        event.preventDefault();
        setLoding(!loading)
        generateRecaptchaVerifier();
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, '+91' + phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setLoding(false)
                // alert("6-digit OTP Sent on your mobile number.")
                setExpandForm(true);
            }).catch((err) => {
                console.error("error", err.code);
                if (err.code === 'auth/too-many-requests') {
                    setErrorMessage('Too Many Attemps, Please try again later.')
                } else {
                    setErrorMessage('An Error occur, Please try again later.')
                }
                setLoding(false)
            });

    }

    const verifyOTPCode = (event) => {
        event.preventDefault();
        setLoding(!loading)
        if (OTP.length === 6) {
            window.confirmationResult.confirm(OTP).then(async (result) => {
                const user = result.user;
                // alert("otp verified");
                localStorage.setItem('phoneNumber', user.auth.currentUser.phoneNumber)
                localStorage.setItem('name', user.auth.currentUser.displayName)
                const token = await auth.currentUser.getIdToken(true)
                console.log('tokenPhone', token);
                token && getuserRole(token);
                // console.log(user);
                setIsValidOTP(true)
            }).catch((error) => {
                console.error(error);
                console.log(error.message); // Log the error code
                setIsValidOTP(false);
                setErrorMessage('Please enter valid OTP.');
                setLoding(false);
            });
        }
    }

    // valid Mobile Number 
    const isValidPhone = (phoneNo) => {
        let pattern = /^[7-9]{1}[0-9]{9}$/;
        return pattern.test(phoneNo);
    }

    // handle phone input 
    const handlePhoneNumber = (event) => {
        setIsPhoneButtonDisable(false)
        if (event.target.value) {
            setErrorMessage('')
            setIsGoogleButtonDisable(true)
        } else {
            setIsGoogleButtonDisable(false)
        }

        if (event.target.value.length === 10) {
            setPhoneNumber(event.target.value);
            setIsValidPhoneNumber(true)
        } else {
            // setPhoneNumber(event.target.value);
            setIsValidPhoneNumber(false)
        }
    }

    // handle otp input
    const handleInputOTP = (event) => {
        setErrorMessage('')
        if (event.target.value.length === 6) {
            setOTP(event.target.value)
            setIsValidOTP(true)
        } else {
            setIsValidOTP(false)
        }
    }


    return (
        <div>
            <div className='parent-container'>
                <form onSubmit={expandForm ? verifyOTPCode : onSignInSubmit}>
                    <CContainer style={{ width: '400px' }}>
                        <CCard>
                            <div className='display-flex justify-content-center'>
                                <CCardImage className='logo-size' orientation="top" src={upjao_logo} />
                            </div>
                            <CCardBody>
                                <CCardTitle className='APP-COLOR'>LOGIN</CCardTitle>
                                {errorMessage && <CAlert color="danger" className="d-flex align-items-center p-1">
                                    <CIcon onClick={() => setErrorMessage('')} icon={icon.cilXCircle} className="flex-shrink-0 me-2 cursor" width={20} height={20} />
                                    <div className='fs-12'>{errorMessage}</div>
                                </CAlert>}
                                <CInputGroup className="mb-3">
                                    <CInputGroupText><GiPhone size={25} className='APP-COLOR' /></CInputGroupText>
                                    <CFormInput
                                        placeholder="Enter Phone Number"
                                        type="tel"
                                        id='phone'
                                        valid={phoneNumber && isValidPhoneNumber}
                                        invalid={phoneNumber && !isValidPhoneNumber}
                                        min={1}
                                        autoFocus
                                        maxLength={10}
                                        // pattern='\D+'
                                        pattern="[0-9.]+"
                                        onChange={(e) => handlePhoneNumber(e)}
                                        required
                                    />
                                </CInputGroup>

                                {expandForm && <div>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupText id="basic-addon1"><RiLockPasswordFill size={25} className='APP-COLOR' /></CInputGroupText>
                                        <CFormInput
                                            type='tel'
                                            placeholder='Enter OTP'
                                            id='otp'
                                            min={1}
                                            valid={OTP && isValidOTP}
                                            invalid={OTP && !isValidOTP}
                                            maxLength={6}
                                            // pattern='\d{6}'
                                            autoFocus
                                            // feedback={!isValidOTP && <h6 className='text-align-left'>Invalid OTP</h6>}
                                            onChange={(e) => handleInputOTP(e)}
                                            required
                                        />
                                    </CInputGroup>
                                </div>}

                                {loading
                                    ? <CButton
                                        className='w-100per'
                                        color={"primary"}
                                        id='loading'
                                    >
                                        <CSpinner size='sm' /> Loading...
                                    </CButton>
                                    : <CButton
                                        className='w-100per'
                                        color={"primary"}
                                        variant="outline"
                                        type='submit'
                                        id='verify-button'
                                        disabled={isPhoneButtonDisable}
                                    > {expandForm ? "VERIFY OTP" : "VERIFY PHONE"}
                                    </CButton>}

                                <br></br>
                                <br></br>
                                <h4 className='APP-COLOR'>OR</h4>
                                <br></br>
                                <CButton
                                    color={'primary'}
                                    className='w-100per' variant="outline"
                                    onClick={signInwithGoogle}
                                    disabled={isGoogleButtonDisable}
                                >
                                    {!googleLoading ? <div><CImage rounded src="https://gitlab.com/assets/auth_buttons/google_64-9ab7462cd2115e11f80171018d8c39bd493fc375e83202fbb6d37a487ad01908.png" width={20} height={20} />
                                        &nbsp; <span style={{ fontWeight: '500' }} id='loginWithGoogle'>LOGIN WITH GOOGLE</span> </div> : <div id='googleLoadingSpinner'><CSpinner size='sm' /> &nbsp; <span style={{ fontWeight: '500' }}>Loading...</span></div>}
                                </CButton>
                            </CCardBody>
                        </CCard>
                    </CContainer>
                </form>
            </div>
            <div id='recaptcha-container'></div>
        </div>
    )
}

export default Login